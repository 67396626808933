import React, { useState, useEffect } from 'react';
import userAction from "../../actions/UserAction";
import { NavLink, Navigate } from 'react-router-dom';
import CustomNotification from "../CustomNotification";
import { NotificationContainer } from "react-notifications";
import Footer from "../footer";
import BlockUi from "react-block-ui";
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './LogoStyle.css';


function LoginComponent(props) {
  const [blocking, setBlocking] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToCraConsultant, setRedirectToCraConsultant] = useState(false);
  const [errorAuth, setErrorAuth] = useState(false);

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setEmailValid(false);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
    setPasswordValid(false);
  };

  const login = (event) => {
    event.preventDefault();
    if (email === '' || password === '') {
      if (email === '') setEmailValid(true);
      if (password === '') setPasswordValid(true);
    } else {
      if (email && password) {
        setBlocking(true);
        userAction
          .signIn(email, password)
          .then((result) => {
            sessionStorage.setItem('email', email);
            let responseJson = result;
            if (responseJson.data) {
              sessionStorage.setItem('data', responseJson.data.token);
              sessionStorage.setItem('role', responseJson.data.role[0]);
              setBlocking(false);
              if (responseJson.data.role[0] === 'ROLE_ADMIN') {
                setRedirectToReferrer(true);

             
                navigate(from, { replace: true })
                    

              } else if (responseJson.data.role[0] === 'ROLE_USER') {
                setRedirectToCraConsultant(true);
                navigate(from, { replace: true })
              }
            }
          })
          .catch((error) => {
            CustomNotification.createNotification('error', "Email ou mot de passe incorrect", 'Connexion');
            setErrorAuth(true);
            setBlocking(false);
          });
      }
    }
  };

  const forgotByEmail = () => {
    if (email === '') setEmailValid(true);
    else {
      userAction.forgotByEmail(email);
      sessionStorage.setItem('email', email);
      props.history.push({
        pathname: '/forgot',
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
      if (isMounted) {
        setBlocking(false);
      }
    };
  }, []);

  if (redirectToReferrer) {
    return <Navigate to={'/calendar'} />;
  }
  if (redirectToCraConsultant) {
    return <Navigate to={'/calendar-user'} />;
  }

  return (
    <div className="container-scroller">
    <NotificationContainer/>
    <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="image-container">
                                                    <img src={require('../../images/LGSmaboitemanager.png')} alt="" width="100" height="100"/>
                                                </div>
                <h4>Salut! Commençons</h4>
                <h6 className="font-weight-light">Connectez-vous pour continuer</h6>
                <form className="pt-3" onSubmit={login}>
                  <div className={`form-group ${emailValid ? "has-danger" : ""}`}>
                    <input type="email" autoFocus className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" required onChange={(e) => emailHandler(e)} />
                  </div>
                  <div className={`form-group ${passwordValid ? "has-danger" : ""}`}>
                    <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Mot de passe" required onChange={(e) => passwordHandler(e)} />
                  </div>
                  <div className="mt-3">
                    <input className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn bnt-color-text" type="submit" value="Se connecter" />
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <NavLink to="#" className="auth-link text-black" onClick={forgotByEmail} type="submit">Mot de passe oublié?</NavLink>
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Vous n'avez pas de compte? <NavLink to="/register" className="text-primary">Crée un compte</NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Footer className="footer-absolute"/>
  </div>
  
        );
    
}

export default LoginComponent;