import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";

const Layout = () => {

    return (

        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper">
                <div className="main-panel">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout;