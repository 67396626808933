import React, {Component} from 'react';
import {Select} from "@material-ui/core";
import {formatDate} from "../utils/datFormat";
import ConsultantAction from "../actions/ConsultantAction";
import CustomNotification from "./CustomNotification";
import ConsultantTable from "./ConsultantTable";
import InputTextGroup from "./InputTextGroup";
import 'react-block-ui/style.css';
import ConsultantCraTable from "./ConsultantCraTable";
import Select2 from 'react-select2-wrapper';


class FormAddConsultant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentConsultant: {},
            blocking: false,
            nomConsultant: "",
            prenomConsultant: "",
            dateNaissance: "",
            lieuNaissance: "",
            nationalite: "",
            sexe: "",
            sexeValid: false,
            typeContrat: "",
            emploi: "",
            qualification: "",
            typeTitreTravail: "",
            tel: "",
            mail: "",
            dateEntre: "",
            dateSortie: "",
            consultant: {},
            date: new Date(),
            consultantId: {},
            selectedConsultantValid: false,
            refrech: true,
            error: {
                nomConsultant: false,
                prenomConsultant: false,
                dateNaissance: false,
                lieuNaissance: false,
                nationalite: false,
                sexeValid: false,
                typeContrat: false,
                emploi: false,
                qualification: false,
                typeTitreTravail: false,
                tel: false,
                mail: false,
                dateEntre: false,
                dateSortie: false,
            }

        };
        this.updateConsultantDetails = this.updateConsultantDetails.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteConsultant = this.deleteConsultant.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    handleInputChange(event) {
        let {name: fieldName, value} = event.target;
        this.setState({
            [fieldName]: value
        });
        let errorTmp = {};
        if (value !== "") {
            errorTmp = {...this.state.error, [fieldName]: false}
            this.setState({
                error: errorTmp
            });
        }
    };

    updateConsultantDetails(consultant) {
        this.setState({
            selectedConsultant: consultant.id,
            nomConsultant: consultant.name.split(' ')[0] || "",
            prenomConsultant: consultant.name.split(' ')[1] || "",
            dateNaissance: formatDate(new Date(consultant.dateNaissance)) || "",
            lieuNaissance: consultant.lieuNaissance || "",
            nationalite: consultant.nationalite || "",
            sexe: consultant.sexe || "",
            typeContrat: consultant.typeContrat || "",
            emploi: consultant.emploi || "",
            qualification: consultant.qualification || "",
            typeTitreTravail: consultant.typeTitreTravail || "",
            tel: consultant.tel || "",
            mail: consultant.mail || "",
            dateEntre: formatDate(new Date(consultant.dateEntre)) || "",
            dateSortie: formatDate(new Date(consultant.dateSortie)) || "",
            currentConsultant: consultant,
        });
    }


    handleSave(event) {

        const {
            nomConsultant, prenomConsultant, dateNaissance, lieuNaissance, nationalite, selectedConsultant,
            sexe, typeContrat, emploi, qualification, typeTitreTravail, tel, mail, dateEntre, dateSortie, error
        } = this.state;

        if (nomConsultant === "" ||
            prenomConsultant === "" ||
            mail === ""
            // dateNaissance === "" ||
            // lieuNaissance === "" ||
            // nationalite === "" ||
            // sexe === "" ||
            // typeContrat === "" ||
            // emploi === "" ||
            // qualification === "" ||
            // typeTitreTravail === "" ||
            // tel === "" ||
            // dateEntre === ""
        ) {
            let errorTmp = {};
            for (let v in error) {
                if (this.state[v] === "") {
                    errorTmp = {...errorTmp, [v]: true}
                }
            }
            this.setState({
                error: errorTmp
            });
        } else {
            this.setState({blocking: true});
            event.preventDefault()
            let consultant = {
                id: selectedConsultant != null ? selectedConsultant : null,
                nomConsultant: nomConsultant + " " + prenomConsultant,
                dateNaissance: dateNaissance,
                lieuNaissance: lieuNaissance,
                nationalite: nationalite,
                sexe: sexe,
                typeContrat: typeContrat,
                emploi: emploi,
                qualification: qualification,
                typeTitreTravail: typeTitreTravail,
                tel: tel,
                mail: mail,
                dateEntre: dateEntre,
                dateSortie: dateSortie,
            };

            if(selectedConsultant == null){
                ConsultantAction.saveConsultant(consultant).then(
                    () => {
                        this.setState({blocking: false});
                        CustomNotification.createNotification('success', 'Consultant enregistré', 'Gestion des consultants');
                        this.props.getAllConsultant()
                    })
                    .catch(() => {
                        this.setState({blocking: false});
                        CustomNotification.createNotification('error', 'Le consultant n\'a pas été enregistré', 'Gestion des consultants');
                    });
            }else{
                ConsultantAction.patchConsultant(consultant.id , consultant).then(
                    () => {
                        this.setState({blocking: false});
                        CustomNotification.createNotification('success', 'Consultant enregistré', 'Gestion des consultants');
                        this.props.getAllConsultant()
                    })
                    .catch(() => {
                        this.setState({blocking: false});
                        CustomNotification.createNotification('error', 'Le consultant n\'a pas été enregistré', 'Gestion des consultants');
                    });
            }

          
        }

    }

    resetForm(){
        this.setState({
            currentConsultant: {},
            nomConsultant: "",
            prenomConsultant: "",
            dateNaissance: "",
            lieuNaissance: "",
            nationalite: "",
            sexe: "",
            sexeValid: false,
            typeContrat: "",
            emploi: "",
            qualification: "",
            typeTitreTravail: "",
            tel: "",
            mail: "",
            dateEntre: "",
            dateSortie: "",
            consultant: {},
            date: new Date(),
            consultantId: {},
            selectedConsultantValid: false,
            error: {
                nomConsultant: false,
                prenomConsultant: false,
                dateNaissance: false,
                lieuNaissance: false,
                nationalite: false,
                sexeValid: false,
                typeContrat: false,
                emploi: false,
                qualification: false,
                typeTitreTravail: false,
                tel: false,
                mail: false,
                dateEntre: false,
                dateSortie: false,
            },
            selectedConsultant: null
        })
    }

    deleteConsultant(id){
        this.props.deleteConsultant(id);
    }

    render() {
        const {
            nomConsultant, prenomConsultant, dateNaissance, lieuNaissance, nationalite,
            sexe, typeContrat, emploi, qualification, typeTitreTravail, tel, mail, dateEntre, dateSortie, error,
            blocking
        } = this.state;
        return (
            <div className="form-group">
                <ConsultantTable consultants={this.props.consultants}
                                 updateConsultantDetails={this.updateConsultantDetails}
                                 deleteConsultant={this.deleteConsultant}/>
                <table className="table">

                    <thead>
                    <tr>
                        <th colSpan="2">Ajouter/modifier un consultant</th>
                        <th>
                            <button type="submit"
                                    className="btn new-facture "
                                    onClick={this.resetForm}
                            >Nouveau consultant
                            </button>
                        </th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            Prénom
                        </td>
                        <td className="td-imput">
                            <InputTextGroup name="prenomConsultant"
                                            placeholder="Prénom"
                                            value={prenomConsultant}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            error={error.prenomConsultant?"true":"false"}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Nom
                        </td>
                        <td className="td-imput">
                            <InputTextGroup name="nomConsultant"
                                            placeholder="Nom"
                                            value={nomConsultant}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            error={error.nomConsultant?"true":"false"}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Mail
                        </td>
                        <td>
                            <InputTextGroup name="mail"
                                            placeholder="Mail"
                                            value={mail}
                                            type="email"
                                            handleInputChange={this.handleInputChange}
                                            error={error.mail?"true":"false"}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Numéro de téléphone
                        </td>
                        <td>
                            <InputTextGroup name="tel"
                                            placeholder="Numéro de téléphone"
                                            value={tel}
                                            type="number"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Lieu de Naissance
                        </td>
                        <td>
                            <InputTextGroup name="lieuNaissance"
                                            placeholder="Lieu de naissance"
                                            value={lieuNaissance}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Date de naissance
                        </td>
                        <td>
                            <InputTextGroup name="dateNaissance"
                                            placeholder="Date de naissance"
                                            value={!dateNaissance ? '' : dateNaissance}
                                            type="date"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Nationalité
                        </td>
                        <td>
                            <InputTextGroup name="nationalite"
                                            placeholder="Nationalité"
                                            value={nationalite}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Civilité
                        </td>
                        <td>
                            <div
                                className="form-group row">
                                <Select2
                                    name="sexe"
                                    value={sexe}
                                    data={[{id:"M",text:"Homme"},{id:"F",text:"Femme"}]}
                                    onChange={this.handleInputChange}
                                    options={{
                                        placeholder: 'Civilité'
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Emploi
                        </td>
                        <td>
                            <InputTextGroup name="emploi"
                                            placeholder="Emploi"
                                            value={emploi}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Type de contrat
                        </td>
                        <td>
                            <InputTextGroup name="typeContrat"
                                            placeholder="Type de contrat"
                                            value={typeContrat}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Qualification
                        </td>
                        <td>
                            <InputTextGroup name="qualification"
                                            placeholder="Qualification"
                                            value={qualification}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Type et N° du titre de travail
                        </td>
                        <td>
                            <InputTextGroup name="typeTitreTravail"
                                            placeholder="Type et N° du titre de travail"
                                            value={typeTitreTravail}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date d'entrée
                        </td>
                        <td>
                            <InputTextGroup name="dateEntre"
                                            placeholder="Date d'entrée"
                                            value={dateEntre}
                                            type="date"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Date de sortie
                        </td>
                        <td>
                            <InputTextGroup name="dateSortie"
                                            placeholder="Date de sortie"
                                            value={dateSortie}
                                            type="date"
                                            handleInputChange={this.handleInputChange}
                                            required={false}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td align="right">
                            <button className="btn btn-info col-md-12"
                                    onClick={this.resetForm}
                            >Réinitialiser
                            </button>
                        </td>
                        <td align="right">
                            <button className="btn btn-success col-md-8"
                                    onClick={this.handleSave}
                            >Enregistrer {blocking && <i className="fa fa-spinner fa-spin"/>}
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {
                    Object.keys(this.state.currentConsultant).length > 0 &&
                    <ConsultantCraTable currentConsultant={this.state.currentConsultant}/>
                }

            </div>

        );
    }
}

export default FormAddConsultant;