import { Link } from "react-router-dom";
import React from 'react';
import HorizontalMenu from "../horizontalMenu";
import SimpleUserNavBar from "../SimpleUserNavBar";

const Missing = () => {
    const goBack = () => window.history.back();

    return (
        <div>
                {
             sessionStorage.getItem('role') ==='ROLE_ADMIN' ? <HorizontalMenu/>:<SimpleUserNavBar/>

            }
     
        <section style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#f7f7f7'
        }}>
            <h1 style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                color: '#333',
                textAlign: 'center',
                marginBottom: '1rem'
            }}>Page non trouvée.</h1>

            <div style={{
                marginTop: '2rem'
            }}>
                {
                     sessionStorage.getItem('role') ==='ROLE_ADMIN' ? <></>:   <button style={{
                        padding: '1rem 2rem',
                        backgroundColor: '#1b2430',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '1.2rem'
                    }} onClick={goBack}>Retournez</button>
                }
             
            </div>
        </section>
        </div>
    );
}

export default Missing
