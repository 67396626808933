import React, { useState, useEffect } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import './NavBarStyles.css';
import userAction from '../actions/UserAction';
import './LogoStyle.css';



function NavBar() {
  const [clicked, setClicked] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    if (userAction.getToken()) {
      // do nothing
    } else {
      setRedirectToReferrer(true);
    }
  }, []);

  function logout() {
    sessionStorage.setItem('data', '');
    sessionStorage.clear();
    setRedirectToReferrer(true);
  }

  if (redirectToReferrer) {
    return <Navigate to={'/login'} />;
  }

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <>
      <nav>
      

        <div>
          <ul id="navBar" className={clicked ? "#navBar active" : "navBar"}>
            <li>
              <NavLink to="/calendar">
                <span>Facturation</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/consultant">
                <span>Consultants</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/client">
                <span>Clients</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/event">
                <span>Evénements</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/formation">
                <span>Formations</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/finance">
                <span>Finances</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/recapFinance">
                <span>Marge</span>
              </NavLink>
            </li>

            <li >
            <NavLink to="/profile">
                  <label className="email" id='profile-link'>{sessionStorage.getItem('email')}</label>
                </NavLink>
            </li>
            <li>
              <a onClick={logout}>
                <i className="mdi mdi-logout" />
                Déconnexion
              </a>
            </li>
          </ul>
        </div>
        <div id='mobile'>
        <NavLink to="/profile">
                  <label className="email">{sessionStorage.getItem('email')}</label>
                </NavLink>
        </div>
      
        <div id='mobile' onClick={handleClick}>
          <i id='bar' className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
