import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import ConsultantAction from "../actions/ConsultantAction";
import {undefinedToInt} from "../utils/fonctionUtils";
import userAction from "../actions/UserAction";

type
Props = {
    type: string,
    typeLabel: string,
    year: Number,
    recap: Boolean,
    consultantRecap: Boolean,
    resultat: Boolean,
    consultantPage: Boolean,
    edit: Boolean,
    charge: Boolean,
    variableData: Object[],
    selectedConsultant: Object,
    setMargeMoyennePlanned: Function,
    setResultatData: Function,
    setMargeMoyenneReal: Function
};

export default function ConsultantVarialbleTable({consultantPage, edit, charge, setResultatData, resultat, year, consultantRecap, recap, setMargeMoyennePlanned, setMargeMoyenneReal, type, typeLabel, variableData, selectedConsultant}: Props) {

    const {useState} = React;

    const emptyTableMessage = 'Vous devez sélectionner un consultant'
    

    const enableEditType = charge ? {
            title: 'Type', editable: 'never', field: 'typeVariable', lookup: {
                1: 'Bonus',
                2: 'Charge',
                3: '-'
            }
        } :
        {
            title: 'Type', field: 'typeVariable', lookup: {
                1: 'Bonus',
                2: 'Charge',
                3: '-'
            }
        }
    ;

    const columns = [
        {title: consultantRecap ? 'Nom' : 'Libellé', field: 'label'},
        enableEditType,
        {title: 'Total', field: 'total', type: 'numeric', editable: 'never'},
        {title: 'Janv.', field: 'january', type: 'numeric'},
        {title: 'Févr.', field: 'february', type: 'numeric'},
        {title: 'Mars', field: 'march', type: 'numeric'},
        {title: 'Avr.', field: 'april', type: 'numeric'},
        {title: 'Mai', field: 'may', type: 'numeric'},
        {title: 'Juin', field: 'june', type: 'numeric'},
        {title: 'Juill.', field: 'july', type: 'numeric'},
        {title: 'Aout', field: 'august', type: 'numeric'},
        {title: 'Sept.', field: 'september', type: 'numeric'},
        {title: 'Oct.', field: 'october', type: 'numeric'},
        {title: 'Nov.', field: 'november', type: 'numeric'},
        {title: 'Déc.', field: 'december', type: 'numeric'},
    ];


    const [data, setData] = useState(variableData);
    const [lineChange, setLineChange] = useState(1);
    const [consultant, setConsultant] = useState();

    useEffect(() => {

        if (variableData.length>2) {
            setData(variableData);
            setConsultant(selectedConsultant);

        }
        if (recap) {
            setData(variableData);
            setConsultant(selectedConsultant);

        }
    }, [variableData, selectedConsultant]);


    const updateRecap = (data) => {
console.log("data"+data)
        let charge = data.filter(value => value.label === "Total Charge")[0];
        const index = charge.tableData.id;
        charge.january = 0;
        charge.february = 0;
        charge.march = 0;
        charge.april = 0;
        charge.may = 0;
        charge.june = 0;
        charge.july = 0;
        charge.august = 0;
        charge.september = 0;
        charge.october = 0;
        charge.november = 0;
        charge.december = 0;
        charge.total = 0;

        charge.january = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.january), 0));
        charge.february = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.february), 0));
        charge.march = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.march), 0));
        charge.april = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.april), 0));
        charge.may = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.may), 0));
        charge.june = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.june), 0));
        charge.july = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.july), 0));
        charge.august = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.august), 0));
        charge.september = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.september), 0));
        charge.october = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.october), 0));
        charge.november = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.november), 0));
        charge.december = parseInt(data.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.december), 0));
        charge.total = getTotal(charge);

        userAction.saveUserVariable(charge).then(
            () => {
                const dataUpdate = [...data];
                dataUpdate[index] = charge;
                setData([...dataUpdate]);
                setResultatData(charge);
            }
        );

    }

    const updateLine = (dataTmp) => {
        let workDays = dataTmp.filter(value => value.label === "Journées travaillées")[0];
        let jo = dataTmp.filter(value => value.label === "Jours Ouvrés")[0];
        let jf = dataTmp.filter(value => value.label === "Jours Fériés")[0];
        let rtt = dataTmp.filter(value => value.label === "RTT")[0];
        let co = dataTmp.filter(value => value.label === "Congé")[0];
        let ma = dataTmp.filter(value => value.label === "Maladie")[0];
        let marge = dataTmp.filter(value => value.label === "Marge")[0];
        let cg = dataTmp.filter(value => value.label === "Coût global")[0];
        let caht = dataTmp.filter(value => value.label === "CA HT")[0];
        let tjm = dataTmp.filter(value => value.label === "TJM")[0];
        let ic = dataTmp.filter(value => value.label === "Inter-Contrat")[0];
        const index2 = workDays.tableData.id;
        const index4 = marge.tableData.id;
        const index3 = caht.tableData.id;

        workDays.january = parseInt(jo.january) - parseInt(jf.january) - parseInt(ma.january) - parseInt(rtt.january) - parseInt(co.january) - parseInt(ic.january);
        workDays.february = parseInt(jo.february) - parseInt(jf.february) - parseInt(ma.february) - parseInt(rtt.february) - parseInt(co.february) - parseInt(ic.february);
        workDays.march = parseInt(jo.march) - parseInt(jf.march) - parseInt(ma.march) - parseInt(rtt.march) - parseInt(co.march) - parseInt(ic.march);
        workDays.april = parseInt(jo.april) - parseInt(jf.april) - parseInt(ma.april) - parseInt(rtt.april) - parseInt(co.april) - parseInt(ic.april);
        workDays.may = parseInt(jo.may) - parseInt(jf.may) - parseInt(ma.may) - parseInt(rtt.may) - parseInt(co.may) - parseInt(ic.may);
        workDays.june = parseInt(jo.june) - parseInt(jf.june) - parseInt(ma.june) - parseInt(rtt.june) - parseInt(co.june) - parseInt(ic.june);
        workDays.july = parseInt(jo.july) - parseInt(jf.july) - parseInt(ma.july) - parseInt(rtt.july) - parseInt(co.july) - parseInt(ic.july);
        workDays.august = parseInt(jo.august) - parseInt(jf.august) - parseInt(ma.august) - parseInt(rtt.august) - parseInt(co.august) - parseInt(ic.august);
        workDays.september = parseInt(jo.september) - parseInt(jf.september) - parseInt(ma.september) - parseInt(rtt.september) - parseInt(co.september) - parseInt(ic.september);
        workDays.october = parseInt(jo.october) - parseInt(jf.october) - parseInt(ma.october) - parseInt(rtt.october) - parseInt(co.october) - parseInt(ic.october);
        workDays.november = parseInt(jo.november) - parseInt(jf.november) - parseInt(ma.november) - parseInt(rtt.november) - parseInt(co.november) - parseInt(ic.november);
        workDays.december = parseInt(jo.december) - parseInt(jf.december) - parseInt(ma.december) - parseInt(rtt.december) - parseInt(co.december) - parseInt(ic.december);
        workDays.total = getTotal(workDays);


        caht.january = parseInt(workDays.january) * parseInt(tjm.january);
        caht.february = parseInt(workDays.february) * parseInt(tjm.february);
        caht.march = parseInt(workDays.march) * parseInt(tjm.march);
        caht.april = parseInt(workDays.april) * parseInt(tjm.april);
        caht.may = parseInt(workDays.may) * parseInt(tjm.may);
        caht.june = parseInt(workDays.june) * parseInt(tjm.june);
        caht.july = parseInt(workDays.july) * parseInt(tjm.july);
        caht.august = parseInt(workDays.august) * parseInt(tjm.august);
        caht.september = parseInt(workDays.september) * parseInt(tjm.september);
        caht.october = parseInt(workDays.october) * parseInt(tjm.october);
        caht.november = parseInt(workDays.november) * parseInt(tjm.november);
        caht.december = parseInt(workDays.december) * parseInt(tjm.december);
        caht.total = getTotal(caht);


        marge.january = parseInt(caht.january) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.january), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.january), 0));
        marge.february = parseInt(caht.february) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.february), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.february), 0));
        marge.march = parseInt(caht.march) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.march), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.march), 0));
        marge.april = parseInt(caht.april) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.april), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.april), 0));
        marge.may = parseInt(caht.may) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.may), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.may), 0));
        marge.june = parseInt(caht.june) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.june), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.june), 0));
        marge.july = parseInt(caht.july) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.july), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.july), 0));
        marge.august = parseInt(caht.august) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.august), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.august), 0));
        marge.september = parseInt(caht.september) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.september), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.september), 0));
        marge.october = parseInt(caht.october) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.october), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.october), 0));
        marge.november = parseInt(caht.november) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.november), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.november), 0));
        marge.december = parseInt(caht.december) - parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 2).reduce((v, c) => v + parseInt(c.december), 0)) + parseInt(dataTmp.filter(value => parseInt(value.typeVariable) === 1).reduce((v, c) => v + parseInt(c.december), 0));
        marge.total = getTotal(marge);

        let list = [workDays, caht, marge];

        ConsultantAction.updateConsultantVariables(list, consultant)
            .then(() => {
                const dataUpdate = [...dataTmp];
                dataUpdate[index2] = workDays;
                dataUpdate[index3] = caht;
                dataUpdate[index4] = marge;
                setData([...dataUpdate]);
            });

        if (type === "reel") {
            setMargeMoyenneReal(marge.total / 12);
        } else {
            setMargeMoyennePlanned(marge.total / 12);
        }

    }

    const pageSize = () => {
        if (resultat) return 2;
        if (consultantRecap) return 5;
        if (charge) return 10;
        if (consultantPage) return 20;
    }

    const getTotal = (newData) => {
        return undefinedToInt(newData.january) +
            undefinedToInt(newData.february) +
            undefinedToInt(newData.march) +
            undefinedToInt(newData.april) +
            undefinedToInt(newData.may) +
            undefinedToInt(newData.june) +
            undefinedToInt(newData.july) +
            undefinedToInt(newData.august) +
            undefinedToInt(newData.september) +
            undefinedToInt(newData.october) +
            undefinedToInt(newData.november) +
            undefinedToInt(newData.december);
    };

    const initFiledIfUndefined = (newData) => {

        if (newData.january === undefined) newData.january = 0;
        if (newData.february === undefined) newData.february = 0;
        if (newData.march === undefined) newData.march = 0;
        if (newData.april === undefined) newData.april = 0;
        if (newData.may === undefined) newData.may = 0;
        if (newData.june === undefined) newData.june = 0;
        if (newData.july === undefined) newData.july = 0;
        if (newData.august === undefined) newData.august = 0;
        if (newData.september === undefined) newData.september = 0;
        if (newData.october === undefined) newData.october = 0;
        if (newData.november === undefined) newData.november = 0;
        if (newData.december === undefined) newData.december = 0;
    }

    const isEditable = (rowData) => {
        return rowData.label !== 'Jours Ouvrés' && rowData.label !== 'CA HT' &&
            rowData.label !== 'Coût global' && rowData.label !== 'Coût global'
    }
    return (
      <MaterialTable
            id="finance-table"
            className="finance-table"
            title={typeLabel}
            columns={columns}
            data={data}

        
            
            editable={edit ?
                {
                    isEditable: rowData => isEditable(rowData),
                    isDeletable: rowData => isEditable(rowData),
                    onRowAdd: newData =>
    new Promise((resolve, reject) => {
        setTimeout(() => {

            newData.type = type;
            newData.year = year;
            newData.total = getTotal(newData);
            initFiledIfUndefined(newData);
            if (recap) {
                newData.typeVariable = 2;
                newData.type = "reel";
                userAction.saveUserVariable(newData).then(
                    response => {
                        const updatedData = [...data];
                        updatedData.splice(data.length - 2, 0, response.data);
                        setData(updatedData);
                        setLineChange(0);
                        updateRecap(updatedData);
                    }
                );
            } else {
                ConsultantAction.saveConsultantVariable(newData, consultant).then(
                    () => {
                        const updatedData = [...data];
                        updatedData.splice(data.length - 2, 0, newData);
                        setData(updatedData);
                        setLineChange(0);
                    }
                );
            }

            resolve();
        }, 1000)
    }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            newData.total = getTotal(newData);
                            if (recap) {
                                newData.typeVariable = 2;
                                userAction.saveUserVariable(newData).then(
                                    () => {
                                        const dataUpdate = [...data];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setData([...dataUpdate]);
                                        updateRecap([...dataUpdate])
                                    }
                                );
                            } else {
                                ConsultantAction.updateConsultantVariables([newData], consultant)
                                    .then(() => {
                                        const dataUpdate = [...data];
                                        const index = oldData.tableData.id;
                                        dataUpdate[index] = newData;
                                        setData([...dataUpdate]);
                                        updateLine([...dataUpdate]);
                                    });
                            }
                            resolve();

                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                setData([...dataDelete]);
                                if (recap){
                                    userAction.deleteUserVariable(oldData.id)
                                        .then(res => {
                                            const dataUpdate = [...data];
                                            const index = oldData.tableData.id;
                                            dataUpdate.splice(index,1);
                                            setData([...dataUpdate]);
                                            updateRecap([...dataUpdate]);
                                        });}
                                else
                                    ConsultantAction.deleteConsultantVariable(oldData.id, consultant)
                                        .then(res => {
                                            setLineChange(1);
                                        });
                                resolve()
                            }, 1000)
                        }),
                }
                : null}

            options={{
                pageSize: pageSize(),
                pageSizeOptions: [5, 10, 15, 20],
                paginationType: 'stepped'
            }}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'Lignes'
                },
                toolbar: {
                    nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                    searchPlaceholder: 'Recherche',
                    searchTooltip: 'Recherche'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage : emptyTableMessage,
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
        />      
    )
}