import React, {Component} from 'react';
import Footer from "./footer";
import HorizontalMenu from "./horizontalMenu";
import ConsultantAction from "../actions/ConsultantAction";
import Select2 from 'react-select2-wrapper';
import ConsultantVarialbleTable from "./ConsultantVarialbleTable";
import './RecapFinanceStyles.css';
import { Spinner } from 'react-bootstrap';



class RecapFinance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            salary: 0,
            employerCharge: 52,
            margeMoyennePlanned: 0,
            margeMoyenneReal: 0,
            variableValues: [],
            variableValuesPrevu: [],
            variableValuesReel: [],
            selectedConsultant: {},
            employeeProfitability: {},
            currentList: [],
            currentVariableList: [],
            ResultVariableList: [],
            variableList: [],
            years: [],
            year: 0,
            selectedYear: new Date().getFullYear(),
            loading : false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getAllConsultant = this.getAllConsultant.bind(this);
        this.yearHandleChange = this.yearHandleChange.bind(this);
        this.setResultatData = this.setResultatData.bind(this);
    };

    yearHandleChange(e) {
        var value = e.target.value;
        this.setState({
            selectedYear: value,
        });
        this.setState({
            currentList: this.state.variableList.filter(y => parseInt(y.year) === parseInt(value) && parseInt(y.typeVariable) === 1),
            currentVariableList: this.state.variableList.filter(y => parseInt(y.year) === parseInt(value) && parseInt(y.typeVariable) === 2),
            ResultVariableList: this.state.variableList.filter(y => parseInt(y.year) === parseInt(value) && parseInt(y.typeVariable) === 3)

        })
    }

    setResultatData(charge) {

        let marge = this.state.variableList.filter(value => (value.label === "Total Marge") && (parseInt(value.year) === parseInt(this.state.selectedYear)))[0];
        let res = this.state.variableList.filter(value => value.label === "Résultat"&& (parseInt(value.year) === parseInt(this.state.selectedYear)))[0];

        res.january = marge.january - charge.january;
        res.february = marge.february - charge.february;
        res.march = marge.march - charge.march;
        res.april = marge.april - charge.april;
        res.may = marge.may - charge.may;
        res.june = marge.june - charge.june;
        res.july = marge.july - charge.july;
        res.august = marge.august - charge.august;
        res.september = marge.september - charge.september;
        res.october = marge.october - charge.october;
        res.november = marge.november - charge.november;
        res.december = marge.december - charge.december;
        res.total = marge.total - charge.total;

        this.setState({
            ResultVariableList: [res]
        })
    }

    handleInputChange(event) {
        let {name: fieldName, value} = event.target;
        this.setState({
            [fieldName]: value
        });
    };


    getAllConsultant() {
        this.setState({ loading: true });

        ConsultantAction.getAllConsulantsRecap().then(
            response => {
                const years = [];
                if (response.data.length > 0) {
                    response.data.map(v => years.push({
                        id: v.year,
                        text: v.year
                    }));

                    const uniqueYears = years.reduce((acc, current) => {
                        return !(acc.find(item => item.id === current.id)) ? acc.concat([current]) : acc;
                    }, []);

                    this.setState({
                        variableList: response.data,
                        years: uniqueYears
                    })
                }
                this.setState({ loading: false });

            }
        )
    };


    componentDidMount() {
        
        this.getAllConsultant();
    }

    render() {
        const {loading,ResultVariableList, currentVariableList, currentList, years, selectedYear, selectedConsultant, variableValuesReel} = this.state;
        return (
            <div>
                <HorizontalMenu/>

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card" id='card-recapFinance'>
                                        <div className="card-body">
                                        {loading ? (
                                                <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                                <>
                                                
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td><label className="col-form-label label-bold">Année</label></td>
                                                        <td>
                                                            <div className="form-group row">
                                                                <Select2
                                                                    value={selectedYear}
                                                                    data={years}
                                                                    onChange={this.yearHandleChange}
                                                                    options={{
                                                                        placeholder: 'Recherche par année'
                                                                    }}
                                                                /></div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <hr/>

                                            <div className="">
                                                <ConsultantVarialbleTable typeLabel="Rentrées"
                                                                          variableData={currentList}
                                                                          selectedConsultant={selectedConsultant}
                                                                          recap={true}
                                                                          consultantRecap={true}
                                                                          year={selectedYear}
                                                />
                                                <ConsultantVarialbleTable typeLabel="Sorties"
                                                                          variableData={currentVariableList}
                                                                          selectedConsultant={selectedConsultant}
                                                                          recap={true}
                                                                          charge={true}
                                                                          edit={true}
                                                                          year={selectedYear}
                                                                          setResultatData={this.setResultatData}
                                                />
                                                <ConsultantVarialbleTable typeLabel="Résultat"
                                                                          variableData={ResultVariableList}
                                                                          selectedConsultant={selectedConsultant}
                                                                          consultantRecap={true}
                                                                          recap={true}
                                                                          resultat={true}
                                                                          year={selectedYear}
                                                />
                                            </div></>
                                            )}


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="footer-relative"/>
            </div>
        );
    }
}

export default RecapFinance;