import React, { Component } from 'react';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import HorizontalMenu from "./horizontalMenu";
import Footer from "./footer"
import EventAction from "../actions/EventAction";
import 'react-notifications/lib/notifications.css';
import FormAddEvent from "./FormAddEvent";
import './EventStyles.css';
import { Spinner } from 'react-bootstrap';


class Event extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blocking: false,
            eventToUpdate: {},
            events: [],
            refrech: true,
            loading: false
        };

        this.getAllEvent = this.getAllEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }


    getAllEvent() {
        this.setState({ loading: true });
        var events = [];
        EventAction.getAllEvents().then(
            response => {
                response.data.map((event) => events.push(event));
                if (response.data.length === 0) {
                    this.setState({ events: [] })
                } else {
                    this.setState({ events: events })
                }
                this.setState({ loading: false });
            }
        )
    }

    componentDidMount() {
        this.getAllEvent();
    }



    deleteEvent(id) {
        this.setState({
            events: this.state.events.filter(event => event.id !== id)
        })
    }

    render() {
        const { events, eventToUpdate, loading } = this.state;

        return (

            <div>
                <HorizontalMenu />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card" id='card-event'>
                                        <div className="card-body">
                                            {loading ? (
                                                <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                                <FormAddEvent
                                                    events={events}
                                                    deleteEvent={this.deleteEvent}
                                                    getAllEvent={() => this.getAllEvent()}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="footer-relative" />
            </div>
        );
    }
}

export default Event;