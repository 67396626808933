import React, {Component} from 'react';
import BottomNavbar from './BottomNavbar';
import TopNavbar from './TopNavbar';
import NavBar from './NavBar';

class HorizontalMenu extends Component {
    render() {
        return (
            // <!-- partial:partials/_horizontal-navbar.html -->
            <div className="horizontal-menu">
                <NavBar/>
                {
                    sessionStorage.getItem('role') ==='ROLE_ADMIN'
                }

            </div>
        );
    }
}

export default  HorizontalMenu;

