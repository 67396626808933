import React, {Component} from 'react';
import userAction from "../../actions/UserAction";
import CustomNotification from "../CustomNotification";
import {NotificationContainer} from "react-notifications";
import Footer from "../footer";
import { NavLink, Navigate } from 'react-router-dom';
import BlockUi from "react-block-ui";
import './LogoStyle.css';

class RegisterComponent extends Component {

    constructor(props) {
        super(props);
       this.state = {
	        blocking: false,
            email: "",
            password: "",
            entrepriseName: "",
            adresse: "",
            codePostal: "",
            tel: "",
            Banque: "",
            RIB: "",
            IBAN_BIC: "",
            siret: "",
            ntva: "",
            emailValid: false,
            passwordValid: false,
            entrepriseNameValid: false,
            adresseValid: false,
            codePostalValid: false,
            telValid: false,
            BanqueValid: false,
            RIBValid: false,
            IBAN_BICValid: false,
            siretValid: false,
            ntvaValid: false,
            redirectToReferrer: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.entrepriseNameHandler = this.entrepriseNameHandler.bind(this);
        this.adresseHandler = this.adresseHandler.bind(this);
        this.codePostalHandler = this.codePostalHandler.bind(this);
        this.telHandler = this.telHandler.bind(this);
        this.BanqueHandler = this.BanqueHandler.bind(this);
        this.RIBHandler = this.RIBHandler.bind(this);
        this.IBAN_BICHandler = this.IBAN_BICHandler.bind(this);
        this.siretHandler = this.siretHandler.bind(this);
        this.ntvaHandler = this.ntvaHandler.bind(this);
        this.emailHandler = this.emailHandler.bind(this);
        this.passwordHandler = this.passwordHandler.bind(this);

    }

    entrepriseNameHandler(e) {
        this.setState({
            entrepriseName: e.target.value,
            entrepriseNameValid: false
        });
    }

    adresseHandler(e) {
        this.setState({
            adresse: e.target.value,
            adresseValid: false
        });
    }

    codePostalHandler(e) {
        this.setState({
            codePostal: e.target.value,
            codePostalValid: false
        });
    }

    telHandler(e) {
        this.setState({
            tel: e.target.value,
            telValid: false
        });
    }

    BanqueHandler(e) {
        this.setState({
            Banque: e.target.value,
            BanqueValid: false
        });
    }

    RIBHandler(e) {
        this.setState({
            RIB: e.target.value,
            RIBValid: false
        });
    }

    IBAN_BICHandler(e) {
        this.setState({
            IBAN_BIC: e.target.value,
            IBAN_BICValid: false
        });
    }

    siretHandler(e) {
        this.setState({
            siret: e.target.value,
            siretValid: false
        });
    }

    ntvaHandler(e) {
        this.setState({
            ntva: e.target.value,
            ntvaValid: false
        });
    }

    emailHandler(e) {
        this.setState({
            email: e.target.value,
            emailValid: false
        });
    }

    passwordHandler(e) {
        this.setState({
            password: e.target.value,
            passwordValid: false
        });
    }

    onSubmit(event) {
        event.preventDefault();
        if (this.state.email === "" || this.state.entrepriseName === "" || this.state.password === "") {
            if (this.state.email === "") this.setState({emailValid: false});
            if (this.state.entrepriseName === "") this.setState({entrepriseNameValid: false});
            if (this.state.password === "") this.setState({passwordValid: false});
            if (this.state.adresse === "") this.setState({adresseValid: true});
            if (this.state.codePostal === "") this.setState({codePostalValid: true});
            if (this.state.tel === "") this.setState({telValid: true});
        } else {
            this.setState({blocking: true});
            let user = {
                email: this.state.email,
                password: this.state.password,
                entrepriseName: this.state.entrepriseName,
                adresse: this.state.adresse,
                codePostal: this.state.codePostal,
                tel: this.state.tel,
                Banque: this.state.Banque,
                RIB: this.state.RIB,
                IBAN_BIC: this.state.IBAN_BIC,
                siret: this.state.siret,
                ntva: this.state.ntva,
                role: "ADMIN"
            }
            userAction.addUser(user)
                .then(
                    (result) => {
                        if (result.status !== 201) {
                            CustomNotification.createNotification('error', "Cette adresse email existe déjà !", 'Inscription');
                        } else {
                            CustomNotification.createNotification('success', 'Youhou, votre compte est créé', 'Inscription');
                            this.setState({redirectToReferrer: true});
                        }
                        this.setState({blocking: false});
                    });
        }
    }

    render() {
        if (this.state.redirectToReferrer===true) {
            return (<Navigate to={'/login'}/>)
        }
        const {email, password, entrepriseName, adresse, codePostal, tel, Banque, RIB, IBAN_BIC, siret, ntva} = this.state;
        return (
            <div>
                <NotificationContainer/>
                <BlockUi blocking={this.state.blocking}>
                    <div className="container-scroller">
                        <div className="container-fluid page-body-wrapper full-page-wrapper">
                            <div className="main-panel">
                                <div className="content-wrapper d-flex align-items-center auth px-0">
                                    <div className="row w-100 mx-0">
                                        <div className="col-lg-4 mx-auto">
                                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                                <div className="image-container">
                                                    <img src={require('../../images/LGSmaboitemanager.png')} alt="" width="100" height="100"/>
                                                </div>
                                                <h4>Nouveau ici?</h4>
                                                <h6 className="font-weight-light">Inscrivez-vous en 2 minutes.</h6>
                                                <form className="pt-3" onSubmit={this.onSubmit}>
                                                    <div
                                                        className={`form-group ${this.state.emailValid ? "has-danger" : ""}`}>
                                                        <input autoFocus type="email" className="form-control  form-control-lg"
                                                            placeholder="Email" required minLength="2"
                                                            value={email}
                                                            onChange={(e) => this.emailHandler(e)}/>
                                                    </div>

                                                    <div
                                                        className={`form-group ${this.state.passwordValid ? "has-danger" : ""}`}>
                                                        <input type="password" className="form-control form-control-lg"
                                                            placeholder="Mot de passe"
                                                            value={password} required minLength="2"
                                                            onChange={(e) => this.passwordHandler(e)}/>
                                                    </div>
                                                    
                                                    <div
                                                        className={`form-group ${this.state.entrepriseNameValid ? "has-danger" : ""}`}>
                                                        <input type="text" className="form-control form-control-lg"
                                                            placeholder="Nom d'entreprise"
                                                            value={entrepriseName} required minLength="2"
                                                            onChange={(e) => this.entrepriseNameHandler(e)}/>
                                                    </div>
                                                    
                                                    <div
                                                        className={`form-group`}>
                                                        <input type="text" className="form-control form-control-lg"
                                                            placeholder="Adresse"
                                                            value={adresse} minLength="2"
                                                            onChange={(e) => this.adresseHandler(e)}/>
                                                    </div>
                                                    <div
                                                        className={`form-group`}>
                                                        <input type="text" className="form-control form-control-lg"
                                                            placeholder="Code postal"
                                                            value={codePostal} minLength="2"
                                                            onChange={(e) => this.codePostalHandler(e)}/>
                                                    </div>

                                                    <div
                                                        className={`form-group`}>
                                                        <input type="text" className="form-control form-control-lg"
                                                            placeholder="Numéro de téléphone" value={tel}
                                                            minLength="2"
                                                            onChange={(e) => this.telHandler(e)}/>
                                                    </div>
                                                    <div
                                                        className={`form-group`}>
                                                        <input type="text" className="form-control form-control-lg"
                                                            placeholder="SIRET" value={siret}
                                                            minLength="2"
                                                            onChange={(e) => this.siretHandler(e)}/>
                                                    </div>
                                                    <div
                                                        className={`form-group`}>
                                                        <input type="text" className="form-control form-control-lg"
                                                            placeholder="N° TVA" value={ntva}
                                                            minLength="2"
                                                            onChange={(e) => this.ntvaHandler(e)}/>
                                                    </div>
                                                    <div className="mt-3">
                                                        <input className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                                        type='submit'
                                                        value="S'inscrire"/>
                                                    </div>
                                                    <div className="text-center mt-4 font-weight-light">
                                                        Vous avez déjà un compte? <NavLink to="/login"
                                                                                    className="text-primary">S'identifier</NavLink>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Footer className="footer-relative"/>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default RegisterComponent;