import React, {Component} from 'react';
import FormationAction from "../actions/FormationAction";
import CustomNotification from "./CustomNotification";
import FormationTable from "./FormationTable";
import InputTextGroup from "./InputTextGroup";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';

class FormAddFormation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            formationName: "",
            ebook: "",
            test: "",
            login: "",
            password: "",
            description: "",
            formation: {},
            formationId: {},
            selectedFormationValid: false,
            refrech: true,
            error: {
                formationName: false,
                ebook: false,
                test: false,
                login: false,
                password: false,
                description: false,
            }

        };
        this.updateFormationDetails = this.updateFormationDetails.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkboxChange = this.checkboxChange.bind(this);
        this.deleteFormation = this.deleteFormation.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    checkboxChange(event) {
        let {name: fieldName, checked} = event.target;
        this.setState({
            [fieldName]: checked
        });
    }

    handleInputChange(event) {
        let {name: fieldName, value} = event.target;
        this.setState({
            [fieldName]: value
        });
        let errorTmp = {};
        if (value !== "") {
            errorTmp = {...this.state.error, [fieldName]: false}
            this.setState({
                error: errorTmp
            });
        }
    };

    updateFormationDetails(formation) {
        this.setState({
            selectedFormation: formation.id,
            formationName: formation.formationName || "",
            ebook: formation.ebook || "",
            test: formation.test || "",
            login: formation.login || "",
            password: formation.password || "",
            description: formation.description || ""
        });
    }


    handleSave(events) {

        const {
            formationName, ebook, test, login, password, selectedFormation, error, description
        } = this.state;

        if (formationName === "") {
            let errorTmp = {};
            for (let v in error) {
                if (this.state[v] === "") {
                    errorTmp = {...errorTmp, [v]: true}
                }
            }
            this.setState({
                error: errorTmp
            });
        } else {
            this.setState({blocking: true});
            events.preventDefault();
            let formation = {
                id: selectedFormation != null ? selectedFormation : null,
                formationName: formationName,
                ebook: ebook,
                test: test,
                login: login,
                password: password,
                description: description
            };
            FormationAction.saveFormation(formation).then(
                () => {
                    this.setState({blocking: false});
                    CustomNotification.createNotification('success', 'Formation enregistrée', 'Gestion des formations');
                    this.props.getAllFormation();
                    this.resetForm()
                })
                .catch(() => {
                    this.setState({blocking: false});
                    CustomNotification.createNotification('error', 'La formation n\'a pas été enregistrée', 'Gestion des formations');
                });
        }

    }
    resetForm(){
        this.setState({
            formationName: "",
            ebook: "",
            test: "",
            login: "",
            password: "",
            description: "",
            formation: {},
            formationId: {}
        })
    }

    deleteFormation(id) {
        this.props.deleteFormation(id)
    }

    render() {
        const {
            formationName, ebook, test, login, password, error, description, blocking
        } = this.state;
        return (
            <div className="form-group">
                <BlockUi blocking={this.state.blocking}>

                    <FormationTable formations={this.props.formations}
                                    updateFormationDetails={this.updateFormationDetails}
                                    deleteFormation={this.deleteFormation}/>
                    <table className="table">
                        <tbody>
                        <tr>
                            <th colSpan="2">Ajouter/modifier une formation</th>
                            <th>
                                <button type="submit"
                                        className="btn new-facture "
                                        onClick={this.resetForm}
                                >Nouvelle formation
                                </button>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                Nom
                            </td>
                            <td className="td-imput">
                                <InputTextGroup name="formationName"
                                                placeholder="Nom"
                                                value={formationName}
                                                type="text"
                                                handleInputChange={this.handleInputChange}
                                                error={error.formationName?"true":"false"}
                                                disabled={blocking}
                                />
                            </td>
                            <td>
                                E-book
                            </td>
                            <td>
                                <InputTextGroup name="ebook"
                                                placeholder="E-book"
                                                value={ebook}
                                                handleInputChange={this.handleInputChange}
                                                error={error.ebook?"true":"false"}
                                                disabled={blocking}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Login
                            </td>
                            <td className="td-imput">
                                <InputTextGroup name="login"
                                                placeholder="Login"
                                                value={login}
                                                handleInputChange={this.handleInputChange}
                                                error={error.login?"true":"false"}
                                                disabled={blocking}
                                />
                            </td>
                            <td>
                                Mot de passe
                            </td>
                            <td>
                                <InputTextGroup name="password"
                                                placeholder="Mot de passe"
                                                value={password}
                                                handleInputChange={this.handleInputChange}
                                                error={error.password?"true":"false"}
                                                disabled={blocking}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Lien vers le test
                            </td>
                            <td>
                                <InputTextGroup name="test"
                                                placeholder="Mettre le lien vers le test"
                                                value={test}
                                                handleInputChange={this.handleInputChange}
                                                error={error.test?"true":"false"}
                                                disabled={blocking}
                                />
                            </td>
                            <td>
                                Description
                            </td>
                            <td>
                                <InputTextGroup name="description"
                                                placeholder="description"
                                                value={description}
                                                handleInputChange={this.handleInputChange}
                                                error={error.description?"true":"false"}
                                                disabled={blocking}

                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td align="right">
                                <button className="btn btn-info col-md-12"
                                        onClick={this.resetForm}
                                >Réinitialiser
                                </button>
                            </td>
                            <td align="right">
                                <button className="btn btn-success col-md-8"
                                        onClick={this.handleSave}
                                >Enregistrer {blocking && <i className="fa fa-spinner fa-spin"/>}
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </BlockUi>
            </div>
        );
    }
}

export default FormAddFormation;