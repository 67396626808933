import {NotificationManager} from "react-notifications";

class CustomNotification{
    createNotification(type, msg, title) {
        switch (type) {
            case 'info':
                NotificationManager.info(msg);
                break;
            case 'success':
                NotificationManager.success(msg, title);

                break;
            case 'warning':
                NotificationManager.warning(msg, 'Close after 3000ms', 3000);
                break;
            case 'error':
                NotificationManager.error(msg, title, 5000, () => {
                    alert('callback');
                });
                break;
            default:
        }
    };
}
export default new CustomNotification();