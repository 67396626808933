import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class TopNavbarConnexion extends Component {

  

    render() {
       const divStyle = {
        backgroundColor: '#1b2430',
        color :'FFFFF',
        height: '80px'
                  }
        return(

            <div>
            <nav className="navbar top-navbar col-lg-12 col-12 p-0" style={divStyle}>
                <div className="container">

                    <div className="navbar-menu-wrapper d-flex align-items-center">
                        <ul className="navbar-nav navbar-nav-right">
                            <li className="nav-item nav-profile dropdown mr-0 mr-sm-2">
                                <NavLink to="/register" className="sign">
                                    Inscription
                                </NavLink>
                            </li>
                            <li className="nav-item nav-profile dropdown mr-0 mr-sm-2">
                                <NavLink to="/login" className="sign">
                                    Connexion
                                </NavLink>
                            </li>
                            </ul>
                        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                                type="button" data-toggle="horizontal-menu-toggle">
                        </button>
                    </div>
                </div>
            </nav>
            </div>
        );

     }
}
export default TopNavbarConnexion;
