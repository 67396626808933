import React, {useState} from 'react';
import MaterialTable from 'material-table';
import ClientAction from "../actions/ClientAction";
import CustomNotification from "./CustomNotification";
import {ConfirmationModal} from "./Modals/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";


type Props = {
    clients: Object[],
    updateClientDetails: Function
};

export default function ClientTable({clients, updateClientDetails,deleteClient}: Props) {

    const [id, setId] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const columns = [
        {title: 'Entreprise', field: 'nomEntreprise'},
        {title: 'Contact', field: 'nomClient'},
        {title: 'Mail', field: 'mail'},
        {title: 'Tél.', field: 'tel'},
        {title: 'Adresse', field: 'adresse'},
        {
            title: 'Favori',
            field: 'favorite',
            lookup: { true: 'Oui', false: 'Non' },
        },
        {
            title: 'Client final',
            field: 'finale',
            lookup: { true: 'Oui', false: 'Non' },
        },

    ];

    const deleteClientService =()=> {

        ClientAction.deleteClient(id).then(
            () => {
                CustomNotification.createNotification('success', 'Client supprimé', 'Gestion des clients');
                deleteClient(id)
            })
            .catch(() => {
                CustomNotification.createNotification('error', 'Le client n\'a pas été supprimé', 'Gestion des clients');
            });
    }

    const getClientToUpdate = (id) => {
        let c = clients.filter(c => c.id === id);
        updateClientDetails(c[0]);
    };

    const deleteClientInModal = (id) => {
        if (id !== undefined) {
            setId(id)
            setShowConfirmModal(true);
        }
    }

    return (
        <>
        <MaterialTable
            style={{ width: "100%" }}
            title="Clients"
            columns={columns}
            data={clients}
            options={{
                filtering: true
            }}
            actions={[
                {
                    icon: 'delete',
                    tooltip: 'Supprimer',
                    onClick: (event, rowData) => deleteClientInModal(rowData.id)
                },
                {
                    icon: 'edit',
                    tooltip: 'Modifier',
                    onClick: (event, rowData) => getClientToUpdate(rowData.id)
                }
            ]}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'Clients'
                },
                toolbar: {
                    nRowsSelected: '{0} client(s) sélectionné(s)',
                    searchPlaceholder: 'Recherche',
                    searchTooltip: 'Recherche'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: clients.length!==0?<CircularProgress />:"Vous n'avez pas ajouté de client",
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
        />
            <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal}
                               action={() => deleteClientService()}/>
        </>
    );
}