import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginComponent from './components/userComponents/LoginComponent'
import ForgotPassword from './components/userComponents/forgotPassword'
import Calendar from './components/Calendar'
import Consultant from './components/Consultant'
import Client from './components/Client'
import Event from './components/Event'
import Formation from './components/Formation'
import Register from "./components/userComponents/RegisterPage";
import IndexComponent from "./components/IndexComponent";
import FormFinance from "./components/FormFinance";
import RecapFinance from "./components/RecapFinance";
import Profile from "./components/Profile";
import CalendarUser from "./components/CalendarUser";
import Layout from './components/Layout';
import RequireAuth from './components/hooks/RequireAuth';
import Unauthorized from './components/userComponents/Unauthorized';
import Missing from './components/userComponents/Missing';


function App() {
    return (


        <Routes>
            <Route exact path="/" element={<Layout />}>

                {/* public routes */}
                <Route exact path="/" element={<IndexComponent />} />
                <Route exact path="/login" element={<LoginComponent />} />
                <Route exact path="/forgot" element={<ForgotPassword />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/unauthorized" element={<Unauthorized />} />


                {/* admin routes */}

                <Route element={<RequireAuth allowedRoles={'ROLE_ADMIN'} />}>

                    <Route exact path="/calendar" element={<Calendar />} />
                    <Route exact path="/consultant" element={<Consultant />} />
                    <Route exact path="/finance" element={<FormFinance />} />
                    <Route exact path="/recapFinance" element={<RecapFinance />} />
                    <Route exact path="/client" element={<Client />} />
                    <Route exact path="/event" element={<Event />} />
                    <Route exact path="/formation" element={<Formation />} />
                </Route>

                {/* user routes and commun routes */}

                <Route element={<RequireAuth allowedRoles={'ROLE_USER'} />}>
                    <Route exact path="/calendar-user" element={<CalendarUser />} />
                </Route>

                <Route exact path="/profile" element={<Profile />} />
                <Route path="*" element={<Missing />} />
            </Route>
        </Routes>



    );
}


export default App;
