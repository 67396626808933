import axios from 'axios'
import {api} from "../api";


class ConsultantAction{
    getAllConsultants() {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/all",
            {
                headers: headers
            }
        );
    }
    getAllConsulantsRecap() {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/user/recap",
            {
                headers: headers
            }
        );
    }
    getConsulant(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/"+id,
            {
                headers: headers
            }
        );
    }
    saveConsultant(newConsultant) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/add",newConsultant,
            {
                headers: headers
            }
        );
    }


    patchConsultant(consultantId, updatedConsultant) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.patch(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/patchConsultant/" + consultantId, updatedConsultant,
            {
                headers: headers
            }
        );
    }


    saveSalary(salary,id,ec,year) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/salary/"+salary+"/"+ec+"/"+id+"/"+year,
            {
                headers: headers
            }
        );
    }
    saveConsultantVariable(variable,id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/save/variable/"+id,variable,
            {
                headers: headers
            }

            );
    }
    addYear(id,year) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/addYear/"+id+"/"+year,
            {
                headers: headers
            }

        );
    }

    updateConsultantVariables(variables,id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/update/variable/"+id,variables,
            {
                headers: headers
            }

        );
    }


    deleteConsultantVariable(variable,id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/delete/v/"+variable+"/"+id,
            {
                headers: headers
            }
        );
    }
    deleteConsultant(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/consultant/delete/"+id,
            {
                headers: headers
            }
        );
    }
    getAllCras(email){
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/cra/cras/"+email,
            {
                headers: headers
            }
        );
    }

    getAllCrasForConsultant(){
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/cra/cras",
            {
                headers: headers
            }
        );
    }
}
export default new ConsultantAction();