import React, {Component} from 'react';
import {formatDate} from "../utils/datFormat";
import EventAction from "../actions/EventAction";
import CustomNotification from "./CustomNotification";
import EventTable from "./EventTable";
import InputTextGroup from "./InputTextGroup";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';

class FormAddEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            eventName: "",
            adresse: "",
            participation: false,
            duration: "",
            description: "",
            date: "",
            event: {},
            eventId: {},
            selectedEvent: null,
            selectedEventValid: false,
            refrech: true,
                error: {
                eventName: false,
                adresse: false,
                participation: false,
                duration: false,
                date: false,
                description: false,
            }
        };
        this.updateEventDetails = this.updateEventDetails.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkboxChange = this.checkboxChange.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    handleInputChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value,
            error: {
                ...this.state.error,
                [name]: !value
            }
        });
    }

    checkboxChange(event) {
        let {name: fieldName, checked} = event.target;
        this.setState({
            [fieldName]: checked
        });
    }

    updateEventDetails(event) {
        this.setState({
            selectedEvent: event.id,
            eventName: event.eventName || "",
            adresse: event.adresse || "",
            participation: event.participation || false,
            duration: event.duration || "",
            description: event.description || "",
            date: formatDate(new Date(event.date)),
            error: {
                eventName: false,
                adresse: false,
                participation: false,
                duration: false,
                date: false,
                description: false,
            }
        });
    }

    handleSave(events) {
        events.preventDefault();

        const {
            eventName, adresse, participation, duration, date, selectedEvent, description, error
        } = this.state;

        if (eventName === "" || adresse === "" || duration === "" || date === "" || description === "") {
            this.setState({
                error: {
                    eventName: !eventName,
                    adresse: !adresse,
                    participation: false,
                    duration: !duration,
                    date: !date,
                    description: !description,
                }
            });
        } else {
            const event = {
                id: selectedEvent,
                eventName,
                adresse,
                participation,
                duration,
                description,
                date,
            };
            this.setState({blocking: true});

            const promise = selectedEvent
                ? EventAction.patchEvent(selectedEvent, event)
                : EventAction.saveEvent(event);

            promise.then(() => {
                this.setState({blocking: false});
                CustomNotification.createNotification('success', 'Événement enregistré', 'Gestion des événements');
                this.props.getAllEvent();
                this.resetForm();
            }).catch(() => {
                this.setState({blocking: false});
                CustomNotification.createNotification('error', 'Événement non enregistré', 'Gestion des événements');
            });
        }
    }

    resetForm(){
        this.setState({
            eventName: "",
            adresse: "",
            participation: false,
            duration: "",
            description: "",
            date: "",
            event: {},
            eventId: {},
            selectedEvent:null
        })

    }

    deleteEvent(id) {
       this.props.deleteEvent(id)
    }

    render() {
        const {
            eventName, adresse, participation, duration, date, error, description, blocking
        } = this.state;
        return (
            <div className="form-group">
                <EventTable events={this.props.events}
                            updateEventDetails={this.updateEventDetails}
                            deleteEvent={this.deleteEvent}/>
                <table className="table">
                    <thead>
                    <tr>
                        <th colSpan="2">Ajouter/modifier un événement</th>
                        <th>
                            <button type="submit"
                                    className="btn new-facture "
                                    onClick={this.resetForm}
                            >Nouvel événement
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            Nom
                        </td>
                        <td className="td-imput">
                            <InputTextGroup name="eventName"
                                            placeholder="Nom"
                                            value={eventName}
                                            type="text"
                                            handleInputChange={this.handleInputChange}
                                            error={error.eventName?"true":"false"}
                                            disabled={blocking}
                            />

                        </td>
                        <td>
                            Adresse
                        </td>
                        <td>
                            <InputTextGroup name="adresse"
                                            placeholder="Adresse"
                                            value={adresse}
                                            handleInputChange={this.handleInputChange}
                                            error={error.adresse?"true":"false"}
                                            disabled={blocking}

                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Durée
                        </td>
                        <td className="td-imput">
                            <InputTextGroup name="duration"
                                            placeholder="Durée"
                                            value={duration}
                                            type="number"
                                            min={0}
                                            handleInputChange={this.handleInputChange}
                                            error={error.duration?"true":"false"}
                                            i={"J"}
                                            disabled={blocking}
                            />
                        </td>
                        <td>
                            Date
                        </td>
                        <td>
                            <InputTextGroup name="date"
                                            placeholder="Date d'entrée"
                                            value={date}
                                            type="date"
                                            handleInputChange={this.handleInputChange}
                                            error={error.date?"true":"false"}
                                            disabled={blocking}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Participation
                        </td>
                        <td>
                            <label className="switch">
                                <input type="checkbox" checked={participation} name="participation"
                                       onChange={this.checkboxChange} readOnly={blocking}/>
                                <span className="slider"></span>
                            </label>
                        </td>
                        <td>
                            Description
                        </td>
                        <td>
                            <InputTextGroup name="description"
                                            placeholder="description"
                                            value={description}
                                            handleInputChange={this.handleInputChange}
                                            error={error.description?"true":"false"}
                                            disabled={blocking}

                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td align="right">
                            <button className="btn btn-info col-md-12"
                                    onClick={this.resetForm}
                            >Réinitialiser
                            </button>
                        </td>
                        <td align="right">
                            <button className="btn btn-success col-md-8"
                                    onClick={this.handleSave}
                            >Enregistrer {blocking && <i className="fa fa-spinner fa-spin"/>}
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default FormAddEvent;