import React, {Component} from 'react';
import TopNavbarConnexion from "./TopNavbarConnexion";
import Footer from "./footer";
import './LogoStyle.css';


class IndexComponent extends Component {

    render() {

        return (
            <div>
                <div className="horizontal-menu">
                    <TopNavbarConnexion/>
                </div>
                <div>
                    <img className="image-accueil"
                        src={require('../images/index.jpg')}  width="100%"/>
                </div>
                <div className="contenu">
                    <div className='image-container'>
                    <img src={require('../images/LGSmaboitemanager.png')} alt="" width="100" height="100"/>

                    </div>
                    <h1 className="h1">Ma boîte manager</h1>
                    <h6>
                    Solution de gestion intégrale pour sociétés de service
                    </h6>
                </div>
                <Footer className="footer-fixed"/>
            </div>
        );
    }
}

export default IndexComponent;
