import React, {Component} from 'react';
import Select2 from 'react-select2-wrapper';
import DayPicker, {DateUtils} from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import HorizontalMenu from "./horizontalMenu";
import Footer from "./footer"
import ClientAction from "../actions/ClientAction";
import AddclientModal from "./Modals/addClientModal";
import AddConsultantModal from "./Modals/addConsultantModal";
import 'react-notifications/lib/notifications.css';
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import '../App.css'
import ConsultantCraTable from "./ConsultantCraTable";
import CraAction from "../actions/CraAction";
import SimpleUserNavBar from "./SimpleUserNavBar";
import './CalendarUserStyles.css';


class CalendarUser extends Component {

    getDaysInMonth = date =>
        new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    pushSelectedDays(d){

        const days = [];
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (date.getDay() !== 0 && date.getDay() !== 6)
                days.push(date);
        }
        this.setState({
            selectedDays: days,
            maxDays:days,
            nbjourAfacture:days.length,
            date:d
        });
    }

    constructor(props) {
        super(props);
        const d = new Date();
        const days = [];
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (date.getDay() !== 0 && date.getDay() !== 6)
                days.push(date);
        }
        this.state = {
            nbrDaysinMonth: nbrDaysinMonth,
            blocking: false,
            selectedDays: days,
            disabledDay: [{daysOfWeek: [0, 6]}],
            selectedClient: {},
            nbjourAfacture: days.length,
            date: new Date(),
            client: {},
            clientId: {},
            consultantId: {},
            cra: {},
            consultant: {},
            clients: [
                {}
            ],
            clientsSelect: [
                {}
            ],
            selectedClientValid: false,
            refrech: true
        };

        this.handleDayClick = this.handleDayClick.bind(this);
        this.getAllClient = this.getAllClient.bind(this);
        this.clientHandleChange = this.clientHandleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.appendDays = this.appendDays.bind(this);
        this.splitDays = this.splitDays.bind(this);
        this.handleBlock = this.handleBlock.bind(this);
        this.pushSelectedDays = this.pushSelectedDays.bind(this);
    }


    handleDayClick(day, {selected}) {
        const {selectedDays} = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({selectedDays});
        this.setState({nbjourAfacture: selectedDays.length});
    }


    getAllClient() {
        this.setState({blocking: false});
        var clients = [];
        ClientAction.getAllClients(0).then(
            response => {
                response.data.map((client) => clients.push({text: client.nomEntreprise, id: client.id}));
                this.setState({clients: clients})
                this.setState({blocking: false});

                const idClient = new URLSearchParams(window.location.search).get('client');
                this.setState({selectedClient: idClient});
            }
        )
    }


    componentDidMount() {
        this.getAllClient();
        this.setState({
            consultant: {
                mail: sessionStorage.getItem('email')
            }
        })
    }


    clientHandleChange(e) {
        this.setState({
            selectedClient: e.target.value,
            selectedClientValid: false

        });
    }



    appendDays() {
        let days = "";
        this.state.selectedDays.forEach(day => {
            days += day.getDate() + ","
        })
        days = days.slice(0, days.length - 1);
        return days;
    }

    splitDays(days) {
        let daysDate = days.split(",").map(day => parseInt(day));
        let selectedDays = [];
        let d = new Date();
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (daysDate.includes(date.getDate())) {
                selectedDays.push(date);
            }
        }
        this.setState({
            selectedDays: selectedDays
        })

    }

    handleSave() {
        const {date,selectedClient, nbrDaysinMonth, nbjourAfacture, consultant} = this.state;
        if (selectedClient === {}) {
            if (selectedClient === "") this.setState({selectedClientValid: false});
        } else {
            this.setState({blocking: true});
            let cra = {
                nbJourOuvrable: nbrDaysinMonth,
                nbJourAfacturer: nbjourAfacture,
                nbJourFerie: 0,
                date: date,
                client: parseInt(selectedClient),
                consultant: consultant.mail,
                days:this.appendDays(),
            };
            CraAction.addCra(cra)
                .then(res => {
                    window.location.reload(false);
                })
                .catch(err => {
                })
        }
    }

    handleBlock(value) {
        this.setState({
            blocking: value
        })
    }

    render() {

        return (

            <div>
                <BlockUi blocking={this.state.blocking}>
                    <AddclientModal  handleBlock={this.handleBlock}/>
                    <AddConsultantModal  handleBlock={this.handleBlock}/>
                    <SimpleUserNavBar/>
                    <div className="container-fluid page-body-wrapper">
                        <div className="main-panel" id='main-pannel'>
                            <div className="content-wrapper">
                                <div className="row grid-margin">
                                    <div className="col-12">
                                        <div className="card" id='card-user'>
                                            <div className="card-body">


                                                <div className="content-cra">
                                                    <div>
                                                        <label className="labell-title-facture">Saisir un nouveau compte rendu d'activité</label>
                                                    </div>
                                                    <div className="date-picker">
                                                        <div className="form-group row">
                                                            <div className="col-lg-5 inline-center">
                                                                <label
                                                                    className="col-form-label"><b>Jours de présence</b></label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <DayPicker
                                                                    localeUtils={MomentLocaleUtils}
                                                                    locale={"fr"}
                                                                    selectedDays={this.state.selectedDays}
                                                                    onDayClick={this.handleDayClick}
                                                                    disabledDays={this.state.disabledDay}
                                                                    onMonthChange={this.pushSelectedDays}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`form-group ${this.state.selectedClientValid ? "has-danger" : ""} row date-picker`}>
                                                            <div className="col-lg-5 inline-center">
                                                                <label className="col-form-label"><b>Client</b>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6 select-client">
                                                                <Select2
                                                                    ref="listeClients"
                                                                    value={this.state.selectedClient}
                                                                    data={this.state.clients}
                                                                    onChange={this.clientHandleChange}
                                                                    options={{
                                                                        placeholder: 'recherche par nom'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="btn-action-save">
                                                            <button type="submit"
                                                                    className="btn btn-success col-md-3"
                                                                    onClick={this.handleSave}
                                                            >Enregistrer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cra-table">
                                                    <ConsultantCraTable />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer className="footer-relative"/>
                </BlockUi>
            </div>
        );
    }
}

export default CalendarUser;