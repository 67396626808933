export const formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    let day = dt.toString();
    let monthString = month.toString();

    if (dt < 10) {
        day = '0' + dt;
    }
    if (month < 10) {
        monthString = '0' + month;
    }
    return year + "-" + monthString + "-" + day;
};