import axios from 'axios'
import {api} from "../api";


class EventAction {
    getAllEvents() {
        console.log(process.env.REACT_APP_BACKEND_URL)
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+ "/event/all",
            {
                headers: headers
            }
        );
    }

    saveEvent(newEvent) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+"/event/add", newEvent,
            {
                headers: headers
            }
        );
    }



    patchEvent(eventId, updatedEvent) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.patch(`${process.env.REACT_APP_BACKEND_URL}`+"/event/patchEvent/" + eventId, updatedEvent,
            {
                headers: headers
            }
        );
    }

    deleteEvent(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}`+ "/event/"+id,
            {
                headers: headers
            }
        );
    }
}

export default new EventAction();