import axios from 'axios'
import {api} from "../api";


class CraAction {
    addCra(newcra) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+ "/cra", newcra,
            {
                headers: headers
            }
        );
    }

    deleteCra(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}`+ "/cra/cras/" + id,
            {
                headers: headers
            }
        );
    }
}

export default new CraAction();