import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import ConsultantAction from "../actions/ConsultantAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import CraAction from "../actions/CraAction";
import CustomNotification from "./CustomNotification";
import { withRouter } from 'react-router-dom';



type
Props = {
    currentConsultant? : "",
};

export default function ConsultantCraTable({currentConsultant}: Props , props) {

    const [cras, setCras] = useState([{}]);

    useEffect(() => {
        getConsultantToUpdate()
    }, [currentConsultant]);


    const columns = [
        {title: 'Nombre de jours ouvrables', field: 'nbJourOuvrable'},
        {title: 'Nombre de jours à facturer', field: 'nbJourAfacturer'},
        {title: 'Nombre de jours feriés', field: 'nbJourFerie'},
        {title: 'Client', field: 'clientName'},
        {title: 'Date', field: 'date', type: "date", dateSetting: {locale: "fr-FR"}},

    ];


    const getConsultantToUpdate = () => {
        if (currentConsultant !== undefined) {
            if (currentConsultant.mail !== null) {
                ConsultantAction.getAllCras(currentConsultant.mail).then(
                    (res) => {
                        setCras(res?.data)
                    })
                    .catch(() => {

                    });
            }
            else{
                CustomNotification.createNotification('error', 'Pas de mail renseigné', 'Gestion des consultants');
            }
        } else {
            ConsultantAction.getAllCrasForConsultant().then(
                (res) => {
                    setCras(res?.data)
                })
                .catch(() => {

                });
        }

    };
    const deleteCra = (id) => {
        CraAction.deleteCra(id)
            .then(() => {
                let craList = cras.filter(c => c.id !== id)
                setCras(craList)
            })
            .catch(() => {
            });
    }


    return (
        <>
            {
                cras.length > 0 &&
                <MaterialTable
                    editable={{
                        isEditable: rowData => rowData.keys().length > 1,
                        isDeletable: rowData => !rowData.hasOwnProperty('id'),
                    }}
                    style={{width: "100%"}}
                    title="Comptes rendus d'activité"
                    columns={columns}
                    data={cras}
                    options={{
                        loadingType: 'overlay'
                    }}
                    actions={currentConsultant !== undefined && [
                        {
                            icon: 'add',
                            tooltip: 'Créer la facture correspondante',
                            onClick: (event, rowData) => {
                                props.history.push("/calendar?id=" + currentConsultant.id + "&client=" + rowData.client + "&nbJourAfacturer=" + rowData.nbJourAfacturer+"&days="+rowData.days+"&date="+rowData.date.split('T')[0])
                            }
                        }, {
                            icon: 'delete',
                            tooltip: 'Supprimer',
                            onClick: (event, rowData) => deleteCra(rowData.id)

                        },
                    ]}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'Cra'
                        },
                        toolbar: {
                            nRowsSelected: '{0} CRA(s) sélectionné(s)',
                            searchPlaceholder: 'Recherche',
                            searchTooltip: 'Recherche'
                        },
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: cras.length !== 0 ?
                                <CircularProgress/> : "Vous n'avez pas ajouté de CRA",
                            filterRow: {
                                filterTooltip: 'Filter'
                            }
                        }
                    }}
                />}
        </>
    )
}
;