import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import userAction from "../../actions/UserAction";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            email: sessionStorage.getItem('email'),
            password: "",
            token: ""

        };
        this.saveNewPassword = this.saveNewPassword.bind(this);

    }

    saveNewPassword() {
        if (this.state.email) {
            userAction.newPassword(this.state.token, this.state.password, this.state.email)
                .then(() => {
                    this.props.history.push({
                        pathname: '/login',

                    })
                });
        }
    }

    tokenHandler(e) {
        this.setState({token: e.target.value});
    }

    passwordHandler(e) {
        this.setState({password: e.target.value});
    }

    render() {

        return (

            <div>
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper full-page-wrapper">
                        <div className="main-panel">
                            <div className="content-wrapper d-flex align-items-center auth px-0">
                                <div className="row w-100 mx-0">
                                    <div className="col-lg-4 mx-auto">
                                        <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                            <div className="brand-logo">
                                                <img src={require('../../images/lgsLogo.png')} alt=""/>
                                            </div>
                                            <form className="pt-5">
                                                <h6 className="font-weight-light">Veuillez saisir le code d'initialisation qui vous a été envoyé par mail.</h6>
                                                <div className="form-group">
                                                    <label htmlFor="token">Code d'initialisation</label>
                                                    <input type="text" className="form-control text-center"
                                                           id="token" placeholder="code"
                                                           autoFocus
                                                           onChange={(e) => this.tokenHandler(e)}
                                                    />
                                                    <label htmlFor="examplePassword1">Nouveau mot de passe</label>
                                                    <input type="password" className="form-control text-center"
                                                           id="examplePassword1" placeholder="mot de passe"
                                                           onChange={(e) => this.passwordHandler(e)}
                                                    />
                                                </div>
                                                <div className="mt-5">
                                                    <a className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn bnt-color-text"
                                                       type="submit" onClick={this.saveNewPassword}>Enregistrer</a>
                                                </div>
                                                <div className="mt-3 text-center">
                                                    <a className="auth-link" href="/login">Connectez-vous avec un autre
                                                        compte?</a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;