import axios from 'axios'
import {api} from "../api";


class FormationAction {
    getAllFormations() {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+ "/formation/all",
            {
                headers: headers
            }
        );
    }

    saveFormation(newFormation) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+ "/formation/add", newFormation,
            {
                headers: headers
            }
        );
    }

    deleteFormation(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}`+ "/formation/"+id,
            {
                headers: headers
            }
        );
    }
}

export default new FormationAction();