import React, {Component} from 'react';
import {NotificationContainer} from "react-notifications";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            className:this.props.className?this.props.className:""
        };
    }
    render() {
        return (
            <div>
                <footer className={`footer ${this.state.className}`}>
                    <div className="w-100 clearfix">
                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © {new Date().getFullYear()} <a
                    href="http://www.xinorya.com/" target="_blank">XINORYA</a>. All rights reserved.</span>
                    </div>
                </footer>
                <NotificationContainer/>
            </div>
        );
    }
}

export default Footer;