import React, { Component } from 'react';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import HorizontalMenu from "./horizontalMenu";
import Footer from "./footer"
import ConsultantAction from "../actions/ConsultantAction";
import AddclientModal from "./Modals/addClientModal";
import AddConsultantModal from "./Modals/addConsultantModal";
import 'react-notifications/lib/notifications.css';
import FormAddConsultant from "./FormAddConsultant";
import './ConsultantStyles.css';
import { Spinner } from 'react-bootstrap';


class Consultant extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blocking: false,
            consultantToUpdate: {},
            consultants: [],
            refrech: true,
            loading: false,

        };

        this.getAllConsultant = this.getAllConsultant.bind(this);
        this.deleteConsultant = this.deleteConsultant.bind(this);
    }



    getAllConsultant() {
        this.setState({ loading: true });
        var consultants = [];
        ConsultantAction.getAllConsultants().then(
            response => {
                response.data.map((consultant) => consultants.push(consultant));
                if (response.data.length === 0) {
                    this.setState({ consultants: [] })
                } else {
                    this.setState({ consultants: consultants })
                }
                this.setState({ loading: false });

            }
        )
    }

    componentDidMount() {
        this.getAllConsultant();
    }

    deleteConsultant(id) {
        this.setState({
            consultants: this.state.consultants.filter(c => c.id !== id)
        })
    }

    render() {
        const { consultants, consultantToUpdate, loading } = this.state;

        return (

            <div>
                <AddclientModal />
                <AddConsultantModal />
                <HorizontalMenu />

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card" id='card-consultant'>
                                        <div className="card-body">
                                            {loading ? (
                                                <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                                <FormAddConsultant consultants={consultants} deleteConsultant={this.deleteConsultant} getAllConsultant={() => this.getAllConsultant()} />

                                            )}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="footer-relative" />
            </div>
        );
    }
}

export default Consultant;