import React, {Component} from 'react';
import Footer from "./footer";
import HorizontalMenu from "./horizontalMenu";
import ConsultantAction from "../actions/ConsultantAction";
import Select2 from 'react-select2-wrapper';
import ConsultantVarialbleTable from "./ConsultantVarialbleTable";
import InputTextGroup from "./InputTextGroup";
import CustomNotification from "./CustomNotification";
import {AddYearModal} from "./Modals/AddYearModal";
import './FormFinanceStyles.css';
import { Spinner } from 'react-bootstrap';



class FormFinance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfirmModal: false,
            blocking: false,
            salary: 0,
            employerCharge: 52,
            margeMoyennePlanned: 0,
            margeMoyenneReal: 0,
            variableValues: [],
            variableValuesPrevu: [],
            variableValuesReel: [],
            selectedConsultant: {},
            employeeProfitability: {},
            currentConsultant: {},
            consultants: [],
            years: [],
            year: 0,
            selectedYear: {},
            loading : false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getAllConsultant = this.getAllConsultant.bind(this);
        this.consultantHandleChange = this.consultantHandleChange.bind(this);
        this.setMargeMoyennePlanned = this.setMargeMoyennePlanned.bind(this);
        this.setMargeMoyenneReal = this.setMargeMoyenneReal.bind(this);
        this.saveSalary = this.saveSalary.bind(this);
        this.saveEc = this.saveEc.bind(this);
        this.yearHandleChange = this.yearHandleChange.bind(this);
        this.handleSaveYear = this.handleSaveYear.bind(this);
        this.setShowConfirmModal = this.setShowConfirmModal.bind(this);
    };

    setShowConfirmModal(value) {
        this.setState({
            showConfirmModal: value,
        })
    }


    handleSaveYear(year) {

        if (this.state.selectedConsultant !== null && this.state.selectedConsultant !== "") {
            if (year.length === 4) {
                this.setState({blocking: true});
                ConsultantAction.addYear(this.state.selectedConsultant, year)
                    .then(() => {
                        CustomNotification.createNotification('success', 'Année enregistrée', 'Finance');
                        this.getConsultant(this.state.selectedConsultant);
                        this.setState({
                            year: 0
                        })
                        this.setState({blocking: false});
                    })
                    .catch((error) => {
                        if (error.response.status === 409) {
                            CustomNotification.createNotification('error', "Cette année existe déjà", 'Finance');
                        } else {
                            CustomNotification.createNotification('error', "Année n'est pas enregistrée", 'Finance');
                            this.setState({blocking: false});
                        }
                    });
            } else {
                CustomNotification.createNotification('error', "Format d'année incorrect !", 'Finance');
            }
        } else {
            CustomNotification.createNotification('error', 'Choisir un consultant !', 'Finance');
        }
    };

    saveSalary(value) {
        this.setState({blocking: true});
        ConsultantAction.saveSalary(value, this.state.selectedConsultant, this.state.employerCharge, this.state.selectedYear)
            .then((res) => {
                CustomNotification.createNotification('success', 'Salaire enregistré', 'Finance');
                this.mapConsultantValues(res.data);
                this.setState({blocking: false});
            })
            .catch(() => {
                CustomNotification.createNotification('error', "Salaire n'est pas enregistré", 'Finance');
                this.setState({blocking: false});
            });
    };

    saveEc(value) {
        this.setState({blocking: true});
        ConsultantAction.saveSalary(this.state.salary, this.state.selectedConsultant, value, this.state.selectedYear)
            .then((res) => {
                CustomNotification.createNotification('success', 'Taux de charges patronales enregistré', 'Finance');
                this.mapConsultantValues(res.data)
                this.setState({blocking: false});
            })
            .catch(() => {
                CustomNotification.createNotification('error', "Le taux de charges patronales n'a pas été enregistré", 'Finance');
                this.setState({blocking: false});
            });
    };

    handleInputChange(event) {
        let {name: fieldName, value} = event.target;
        this.setState({
            [fieldName]: value
        });

        if (fieldName === "salary") {
            if (value.length >= 5) {
                this.saveSalary(value);
            }
        }
        if (fieldName === "employerCharge") {
            if (value.length >= 2) {
                this.saveEc(value);
            }
        }

    };

    setMargeMoyennePlanned(value) {
        this.setState({
            margeMoyennePlanned: value
        })
    };

    setMargeMoyenneReal(value) {
        this.setState({
            margeMoyenneReal: value
        })
    };

    getAllConsultant() {
        this.setState({ loading: true });

        this.setState({blocking: true});
        var consultants = [];
        ConsultantAction.getAllConsultants().then(
            response => {
                response.data.map((consultant) => consultants.push({
                    text: consultant.name,
                    id: consultant.id
                }));
                this.setState({consultants: consultants})
                this.setState({blocking: false});
                this.setState({ loading: false });

            }
        )
    };

    getConsultant(id) {
        this.setState({blocking: true});
        ConsultantAction.getConsulant(id).then(
            response => {
                this.mapConsultantValues(response.data)
                this.setState({blocking: false});
            }
        );
    };

    mapConsultantValues(serverConsultant) {
        let year = this.state.selectedYear !== null && this.state.selectedYear !== "" ? this.state.selectedYear : new Date().getFullYear();
        const years = [];
        serverConsultant.employeeProfitability.variableMonthsValues.map(v => years.push({
            id: v.year,
            text: v.year
        }));

        const uniqueYears = years.reduce((acc, current) => {
            return !(acc.find(item => item.id === current.id)) ? acc.concat([current]) : acc;
        }, []);
        let listPrevu = serverConsultant.employeeProfitability.variableMonthsValues.filter(v => v.type === "prevu" && parseInt(v.year) === parseInt(year));
        listPrevu.forEach(item=>{
            Object.keys(item).forEach(key=>{
                if( typeof item[key] == 'number' && !isNaN(item[key])){
                    if (!Number.isInteger(item[key])) {
                        item[key]=Math.trunc(item[key])
                    }
                }
            })
        });
        let listReel = serverConsultant.employeeProfitability.variableMonthsValues.filter(v => v.type === "reel" && parseInt(v.year) === parseInt(year));
        listReel.forEach(item=>{
            Object.keys(item).forEach(key=>{
                if( typeof item[key] == 'number' && !isNaN(item[key])){
                    if (!Number.isInteger(item[key])) {
                        item[key]=Math.trunc(item[key])
                    }
                }
            })
        });
        this.setState({
            employeeProfitability: serverConsultant.employeeProfitability,
            salary: serverConsultant.employeeProfitability.employeeSalaryDetails.filter(v => parseInt(v.year) === parseInt(year))[0].grossAnnualSalary,
            margeMoyennePlanned: serverConsultant.employeeProfitability.employeeSalaryDetails.filter(v => parseInt(v.year) === parseInt(year))[0].grossMarginPerMonthPlanned,
            margeMoyenneReal: serverConsultant.employeeProfitability.employeeSalaryDetails.filter(v => parseInt(v.year) === parseInt(year))[0].grossMarginPerMonthReal,
            employerCharge: serverConsultant.employeeProfitability.employeeSalaryDetails.filter(v => parseInt(v.year) === parseInt(year))[0].employerCharge === null ? 52 : serverConsultant.employeeProfitability.employeeSalaryDetails.filter(v => parseInt(v.year) === parseInt(year))[0].employerCharge,
            currentConsultant: serverConsultant,
            variableValuesPrevu: listPrevu,
            variableValuesReel: listReel,
            years: uniqueYears,
            selectedYear: this.state.selectedYear !== null && this.state.selectedYear !== "" ? this.state.selectedYear : new Date().getFullYear()
        });
    }

    consultantHandleChange(e) {

        var id = e.target.value;
        this.setState({
            selectedConsultant: id,

        });
        this.getConsultant(id);
    };

    yearHandleChange(e) {
        var value = e.target.value;
        this.setState({
            selectedYear: value,
        });
        if (this.state.selectedYear !== Number.parseInt(value)) {
            this.getConsultant(this.state.selectedConsultant);
        }

    }


    componentDidMount() {
        this.getAllConsultant();
    }

    render() {
        const {loading,margeMoyenneReal, margeMoyennePlanned, salary, employerCharge, year, years, selectedYear, selectedConsultant, consultants, variableValuesStatic, employeeProfitability, variableValuesPrevu, variableValuesReel} = this.state;

        return (
            <div>

                <HorizontalMenu/>

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card" id='card-formFinance'>
                                        <div className="card-body">
                                        {loading ? (
                                                <div className="text-center"style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                               <>
                                                       <table>
                                                <tbody>
                                                <tr>
                                                    <td><label className="col-form-label label-bold">Consultant</label>
                                                    </td>
                                                    <td>
                                                        <div className="form-group row">
                                                            <Select2
                                                                value={selectedConsultant}
                                                                data={consultants}
                                                                onChange={this.consultantHandleChange}
                                                                options={{
                                                                    placeholder: 'Recherche par nom'
                                                                }}
                                                            /></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><label className="col-form-label label-bold">Année</label></td>
                                                    <td>
                                                        <div className="form-group row">
                                                            <Select2
                                                                value={selectedYear}
                                                                data={years}
                                                                onChange={this.yearHandleChange}
                                                                options={{
                                                                    placeholder: 'Recherche par Année'
                                                                }}
                                                            /></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button className="btn btn-success"
                                                                onClick={() => this.setShowConfirmModal(true)}
                                                        >Ajouter une année
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <hr/>
                                            <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <label className="col-form-label label-bold">Salaire annuel
                                                            brut</label>
                                                    </td>
                                                    <td>
                                                        <InputTextGroup name="salary"
                                                                        value={salary}
                                                                        type="number"
                                                                        handleInputChange={this.handleInputChange}
                                                                        i={"€"}
                                                        />
                                                    </td>
                                                    <td className="col-form-label label-bold">
                                                        Marge Moyenne prévue par mois
                                                    </td>
                                                    <td className="col-form-label label-bold-green">
                                                        {margeMoyennePlanned.toFixed(2)} €
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="col-form-label label-bold">Charge
                                                            patronale</label>
                                                    </td>
                                                    <td>
                                                        <InputTextGroup name="employerCharge"
                                                                        value={employerCharge}
                                                                        type="number"
                                                                        handleInputChange={this.handleInputChange}
                                                                        i={"%"}
                                                        />
                                                    </td>
                                                    <td className="col-form-label label-bold">
                                                        Marge Moyenne réelle par mois
                                                    </td>
                                                    <td className="col-form-label label-bold-green">
                                                        {margeMoyenneReal.toFixed(2)} €
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <div className="">
                                                <ConsultantVarialbleTable typeLabel="Prévu"
                                                                          type="prevu"
                                                                          variableData={variableValuesPrevu}
                                                                          selectedConsultant={selectedConsultant}
                                                                          setMargeMoyennePlanned={this.setMargeMoyennePlanned}
                                                                          setMargeMoyenneReal={this.setMargeMoyenneReal}
                                                                          edit={true}
                                                                          consultantPage={true}
                                                                          year={selectedYear}
                                                />
                                                <ConsultantVarialbleTable typeLabel="Réel"
                                                                          type="reel"
                                                                          variableData={variableValuesReel}
                                                                          selectedConsultant={selectedConsultant}
                                                                          setMargeMoyenneReal={this.setMargeMoyenneReal}
                                                                          setMargeMoyennePlanned={this.setMargeMoyennePlanned}
                                                                          edit={true}
                                                                          consultantPage={true}
                                                                          year={selectedYear}
                                                />
                                            </div>
                                               </>
                                            )}
                                    
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="footer-relative"/>
                <AddYearModal showConfirmModal={this.state.showConfirmModal}
                              setShowConfirmModal={() => this.setShowConfirmModal()}
                              action={this.handleSaveYear}/>

            </div>
        );
    }
}

export default FormFinance;