import React, {useState} from 'react';
import MaterialTable from 'material-table';
import FormationAction from "../actions/FormationAction";
import CustomNotification from "./CustomNotification";
import {ConfirmationModal} from "./Modals/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";


type
Props = {
    formations: Object[],
    updateFormationDetails: Function
};

export default function FormationTable({formations, updateFormationDetails,deleteFormation}: Props) {

    const [id, setId] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const columns = [
        {title: 'Nom', field: 'formationName'},
        {title: 'E-book', field: 'ebook'},
        {title: 'Test', field: 'test'},
        {title: 'Login', field: 'login'},
        {title: 'Mot de passe', field: 'password'},

    ];

    const deleteFormationService = () => {
        FormationAction.deleteFormation(id).then(
            () => {
                CustomNotification.createNotification('success', 'Formation supprimée', 'Gestion des formations');
                deleteFormation(id)
            })
            .catch(() => {
                CustomNotification.createNotification('error', 'La formation n\'a pas été supprimée', 'Gestion des formations');
            });
    }

    const getFormationToUpdate = (id) => {
        let c = formations.filter(c => c.id === id);
        updateFormationDetails(c[0]);
    };

    const deleteClientInModal = (id) => {
        if (id !== undefined) {
            setId(id)
            setShowConfirmModal(true);
        }
    }
    return (
        <><MaterialTable
            style={{width: "100%"}}
            title="Formations"
            columns={columns}
            data={formations}
            options={{
                filtering: true
            }}
            actions={[
                {
                    icon: 'delete',
                    tooltip: 'Supprimer',
                    onClick: (formation, rowData) => deleteClientInModal(rowData.id)
                },
                {
                    icon: 'edit',
                    tooltip: 'Modifier',
                    onClick: (formation, rowData) => getFormationToUpdate(rowData.id)
                }
            ]}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'Formations'
                },
                toolbar: {
                    nRowsSelected: '{0} formation(s) sélectionnée(s)',
                    searchPlaceholder: 'Recherche',
                    searchTooltip: 'Recherche'
                },
                header: {
                    actions: ''
                },
                body: {
                    emptyDataSourceMessage: formations.length!==0?<CircularProgress />:"Vous n'avez pas ajouté de formation",
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
        />
            <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal}
                               action={() => deleteFormationService()}/></>
    );
}