import React, { useState, useEffect, useRef } from 'react';
import ClientAction from "../../actions/ClientAction";
import { NotificationContainer } from "react-notifications";
import CustomNotification from "../CustomNotification";
import Modal from "react-bootstrap/Modal";

const AddclientModal = (props) => {
  const [blocking, setBlocking] = useState(false);
  const [clientname, setClientname] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [clientnameValid, setClientnameValid] = useState(false);
  const [adresseValid, setAdresseValid] = useState(false);
  const [codePostalValid, setCodePostalValid] = useState(false);

  const inputRef = useRef(null);

  const reset = () => {
    setBlocking(false);
    setClientname("");
    setAdresse("");
    setCodePostal("");
    setClientnameValid(false);
    setAdresseValid(false);
    setCodePostalValid(false);
  };

  const adresseHandler = (e) => {
    setAdresse(e.target.value);
    setAdresseValid(false);
  };

  const codePostalHandler = (e) => {
    setCodePostal(e.target.value);
    setCodePostalValid(false);
  };

  const onChangeName = (e) => {
    setClientname(e.target.value);
    setClientnameValid(false);
  };

  const onVerif = () => {
    setBlocking(true);
    const { setSelectedClient, getAllClient } = props;
    if (clientname === "" || adresse === "" || codePostal === "" ) {
        if(clientname ==="") setClientnameValid(true);
        if(adresse === "") setAdresseValid(true);
        if(codePostal === "") setCodePostalValid(true);

      setBlocking(false);

    } else {
      const newclient = {
        nomEntreprise: clientname,
        adresse: adresse,
        codePostal: codePostal
      };
      ClientAction.saveClient(newclient).then(
        (res) => {
          CustomNotification.createNotification('success', 'Client enregistré', 'Facturation');
          getAllClient();
          setTimeout(() => {
            setSelectedClient(res.data.id)
          }, 1000)
          handleClose()
          setBlocking(false);
        }).catch(() => {
          setBlocking(false);
        });
    }
  };

  const handleClose = () => {
    props.closeModal();
    reset();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.showModal]);

  return (
    <Modal show={props.showModal} onHide={handleClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="ModalLabel">Ajouter un client</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <fieldset>
            <div className={`form-group ${clientnameValid ? "has-danger" : ""}`}>
              <input
                id="clientname"
                className="form-control form-control-lg"
                name="clientname"
                minLength="2"
                type="text"
                required
                placeholder="Nom de l'entreprise"
                value={clientname}
                onChange={onChangeName}
                ref={inputRef}
              />
            </div>
            <div className={`form-group ${ adresseValid ? "has-danger" : ""}`}>
              <input
                type="text"
                required
                className="form-control form-control-lg"
                placeholder="Adresse"
                value={adresse}
                minLength="2"
                onChange={adresseHandler}
              />
            </div>

            <div className={`form-group ${codePostalValid ? "has-danger" : ""}`}>
              <input
                type="text"
                required
                className="form-control form-control-lg"
                                               placeholder="Code Postal"
                                               
                                               value={codePostal} minLength="2"
                                               onChange={codePostalHandler}/>
                                    </div>

                                    <div className="modal-footer">
                                        <button className="btn btn-success"
                                                data-dismiss="modal"
                                                onClick={onVerif}
                                                disabled={blocking}
                                        >Ajouter
                                        </button>

                                        <button type="button" className="btn btn-light" onClick={handleClose}>Fermer
                                        </button>
                                    </div>
                                </fieldset>
                            </div>
                        </div>


                <NotificationContainer/>
            </Modal>
        );
    }


export default AddclientModal;