import axios from 'axios'
import {api} from "../api";


class ClientAction {
    getAllClients(cons) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}` + "/client/all/"+cons,
            {
                headers: headers
            }
        );
    }

    saveClient(newClient) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}` + "/client/add", newClient,
            {
                headers: headers
            }
        );
    }


    updateClient(newClient) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.patch(`${process.env.REACT_APP_BACKEND_URL}` + "/client/update", newClient,
            {
                headers: headers
            }
        );
    }

    patchClient(clientId, updatedClient) {

    var headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('data')
    };
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}`+ "/client/patchClient/" + clientId, updatedClient,
        {
            headers: headers
        }
    );
}

    deleteClient(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}` + "/client/"+id,
            {
                headers: headers
            }
        );
    }
}

export default new ClientAction();