import React, { Component } from 'react';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import HorizontalMenu from "./horizontalMenu";
import Footer from "./footer"
import ClientAction from "../actions/ClientAction";
import 'react-notifications/lib/notifications.css';
import FormAddClient from "./FormAddClient";
import './ClientStyles.css'
import { Spinner } from 'react-bootstrap';


class Client extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blocking: false,
            clientToUpdate: {},
            clients: [],
            refrech: true,
            loading: false,
        };

        this.getAllClient = this.getAllClient.bind(this);
        this.deleteClient = this.deleteClient.bind(this);
    }



    getAllClient() {
        this.setState({ loading: true });

        let clients = [];
        ClientAction.getAllClients(1).then(
            response => {
                response.data.map((client) => clients.push(client));
                if (response.data.length === 0) {
                    this.setState({ clients: [] })
                } else {
                    this.setState({ clients: clients })
                }
                this.setState({ loading: false });
            }
        )
    }

    componentDidMount() {
        this.getAllClient();
    }

    deleteClient(id) {
        this.setState({
            clients: this.state.clients.filter(c => c.id !== id)
        })
    }

    render() {
        const { clients, clientToUpdate, loading } = this.state;

        return (

            <div>
                <HorizontalMenu />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card" id='card-client'>
                                        <div className="card-body">
                                            {loading ? (
                                                <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                                <FormAddClient
                                                    clients={clients}
                                                    getAllClient={() => this.getAllClient()}
                                                    deleteClient={this.deleteClient}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="footer-relative" />
            </div>
        );
    }
}

export default Client;