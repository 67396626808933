import React, {useState} from 'react';
import MaterialTable from 'material-table';
import ConsultantAction from "../actions/ConsultantAction";
import CustomNotification from "./CustomNotification";
import {ConfirmationModal} from "./Modals/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";




type
Props = {
    consultants: Object[],
    updateConsultantDetails: Function
};

export default function ConsultantTable({consultants, updateConsultantDetails,deleteConsultant}: Props) {

    const [id, setId] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const columns = [
        {title: 'Nom', field: 'name'},
        {title: 'Emploi', field: 'emploi'},
        {title: 'Mail', field: 'mail'},
        {title: 'Tél.', field: 'tel'},
        {title: 'Date d\'entrée', field: 'dateEntre', type: "date", dateSetting: { locale: "fr-FR" }},

    ];

    const deleteConsultantService = () => {
        ConsultantAction.deleteConsultant(id).then(
            () => {
                CustomNotification.createNotification('success', 'Consultant supprimé', 'Gestion des consultants');
                deleteConsultant(id);
            })
            .catch(e => {
                console.log(e)
                CustomNotification.createNotification('error', 'Le consultant n\' a pas été supprimé', 'Gestion des consultants');
            });
    }

    const getConsultantToUpdate = (id) => {
        let c = consultants.filter(c => c.id === id);
        updateConsultantDetails(c[0]);
    };

    const deleteConsultantInModal = (id) => {
        if (id !== undefined) {
            setId(id)
            setShowConfirmModal(true);
        }
    }


    return (
        <>
            <MaterialTable
                editable={{
                    isEditable: rowData => rowData.keys().length>1,
                    isDeletable: rowData => !rowData.hasOwnProperty('id')  ,
                }}
                style={{width: "100%"}}
                title="Consultants"
                columns={columns}
                data={consultants}
                options={{
                    loadingType:'overlay'
                }}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Supprimer',
                        onClick: (event, rowData) => deleteConsultantInModal(rowData.id)
                    },
                    {
                        icon:  'edit',
                        tooltip: 'Modifier',
                        onClick: (event, rowData) => getConsultantToUpdate(rowData.id)
                    }
                ]}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'Consultants'
                    },
                    toolbar: {
                        nRowsSelected: '{0} consultants(s) sélectionné(s)',
                        searchPlaceholder: 'Recherche',
                        searchTooltip: 'Recherche'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: consultants.length!==0?<CircularProgress />:"Vous n'avez pas ajouté de consultant",
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
            />
            <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal}
                               action={() => deleteConsultantService()}/>
        </>
    )
}
;