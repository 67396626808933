import axios from 'axios'
import decode from 'jwt-decode';
import {api} from "../api";



class factureAction {
    saveBill(newBill) {

        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };

        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+"/facture/saveBill", newBill,
            {
                headers: headers
            }
        );
    }

    deleteBill(id) {

        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };

        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}`+ "/facture/"+ id,
            {
                headers: headers
            }
        );
    }

    gelAllBills() {

        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/facture/factures",
            {
                headers: headers
            }
        );
    }

    generateFacture(fnId) {
        var headers = {
            'Content-Type': 'application/json'
           };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+ `/facture/${fnId}/facture_${fnId}.pdf`,
            {
                headers: headers
            }
        );
    }
}


export default new factureAction();