import React from 'react';
import { useLocation, Navigate , Outlet} from "react-router-dom";
import useAuth from "./useAuth";
import UserAction from "../../actions/UserAction";

import jwt from 'jsonwebtoken';


const RequireAuth = ({allowedRoles}) =>{
    let {auth} = useAuth();
    const location = useLocation();
            auth = sessionStorage.getItem('data')



const decodedToken = jwt.decode(auth, { complete: true });


            
    return(
        decodedToken?.payload?.rol[0] === allowedRoles
            ?<Outlet/>
            : auth
            ? <Navigate to="/unauthorized" state={{from: location }} replace />
            :<Navigate to="/login" state={{from: location }} replace />

    );
}

export default RequireAuth;