import axios from 'axios'
import decode from 'jwt-decode';
import {api} from "../api";

class UserAction {

    signIn(email, password) {


        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer n2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%C*F-J@NcRf'
        };

        return axios.post(`${process.env.REACT_APP_BACKEND_URL}`+ `/user/signin`,{email:email,password:password},
            {
                headers: headers
            }
        )
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else
                return false;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        sessionStorage.setItem('data', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return sessionStorage.getItem('data')
    }


    getProfile() {
        return decode(this.getToken());
    }

    forgotByEmail(email) {
        var headers = {
            'Content-Type': 'application/json'

        };
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}`+ `/user/forgotByMail/${email}/`,
            {
                headers: headers
            }
        );
    }

    newPassword(token, newPass, email) {
        var headers = {
            'Content-Type': 'application/json'
        };
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}`+ `/user/forgot/${token}/${newPass}/${email}/`,
            {
                headers: headers
            }
        );
    }

    getAll() {
        var headers = {
            'Content-Type': 'application/json'
        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}` + "/user/all",
            {
                headers: headers
            }
        );
    }

    deleteUser(id) {
        var headers = {
            'Content-Type': 'application/json'
        };
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}`+ `/user/delete/${id}`,
            {
                headers: headers
            }
        );
    }

    getUserDetails(id) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}` + `/user/getUser/${id}`,
            {
                headers: headers
            }
        );

    }

    getByEamil(email) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')        };

        return axios.get(`${process.env.REACT_APP_BACKEND_URL}`+"/user/findByEmail/"+email,
            {
                headers: headers
            }
        );
    }

    updateUser(user,passUpdated) {
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('data')
        };
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}` + '/user/update/'+passUpdated, user,
            {
                headers: headers
            }
        );

    }

    addUser(newuser) {
        var headers = {
            'Content-Type': 'application/json',
        };
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}` + "/user/add", newuser,
            {
                headers: headers
            }
        );
    }



    deleteUserVariable(varId) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}` + "/user/delete/variable/" + varId,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('data')
                }
            }
        );
    }

    saveUserVariable(variables) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}` + "/user/save/variable/" , variables,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('data')
                }
            }
        );
    }

}

export default new UserAction();

