import React, { useState, useEffect, useRef } from 'react';
import { NotificationContainer } from "react-notifications";
import ConsultantAction from "../../actions/ConsultantAction";
import CustomNotification from "../CustomNotification";
import Modal from "react-bootstrap/Modal";


const AddConsultantModal = (props) => {

    const [blocking, setBlocking] = useState(false);
    const [consultantname, setConsultantName] = useState("");
    const [consultantMail, setConsultantMail] = useState("");
    const [consultantnameValid, setConsultantNameValid] = useState(false);
    const [consultantMailValid, setConsultantMailValid] = useState(false);

    const inputRef = useRef(null);


    const onVerif = () => {
        setBlocking(true);
        const { getAllConsultant, setSelectedConsultant } = props;
        if (consultantname === "" || consultantMail === "") {
            setConsultantNameValid(consultantname === "");
            setConsultantMailValid(consultantMail === "");
            setBlocking(false);
        } else {
            const newconsultant = {
                nomConsultant: consultantname,
                mail: consultantMail
            };
            ConsultantAction.saveConsultant(newconsultant).then(
                (res) => {
                    CustomNotification.createNotification('success', 'Consultant enregistré', 'Facturation');
                    getAllConsultant();
                    setTimeout(() => {
                        setSelectedConsultant(res.data.id);
                    }, 1000);
                    handleClose();
                    setConsultantName("");
                    setConsultantMail("");
                    setConsultantNameValid(false);
                    setConsultantMailValid(false);
                    setBlocking(false);
                }).catch(() => {
                    setBlocking(false);
                });
        }
    };

    const onChange = (e) => {
        setConsultantName(e.target.value);
        setConsultantNameValid(false);
    };

    const onChangeMail = (e) => {
        setConsultantMail(e.target.value);
        setConsultantMailValid(false);
    };

    const handleClose = () => {
        props.closeModal();
    };

    useEffect(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, [props.showModal]);

      useEffect(() => {
        setConsultantName("");
        setConsultantMail("");
        setConsultantNameValid(false);
        setConsultantMailValid(false);
    }, [props.showModal]);

    return (
        <Modal show={props.showModal} onHide={handleClose}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="ModalLabel">Ajouter un consultant</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => handleClose()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <fieldset>
                        <div className={`form-group ${consultantnameValid ? "has-danger" : ""}`}>
                            <input className="form-control" name="consultantname" minLength="2" type="text" required placeholder="Nom Consultant" onChange={onChange}                 ref={inputRef}
 />
                        </div>
                        <div className={`form-group ${consultantMailValid ? "has-danger" : ""}`}>
                            <input className="form-control" name="consultantMail" minLength="2" type="email" required placeholder="Mail Consultant" onChange={onChangeMail} />
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-success"
                                onClick={onVerif}
                                disabled={blocking}
                            >Ajouter
                            </button>

                            <button type="button" className="btn btn-light" onClick={() => handleClose()}>Fermer
                            </button>
                        </div>
                    </fieldset>
                </div>
            </div>
            <NotificationContainer />
        </Modal>
    );
};

export default AddConsultantModal;