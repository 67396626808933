import React, {Component} from 'react';
import Select2 from 'react-select2-wrapper';
import DayPicker, {DateUtils} from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import HorizontalMenu from "./horizontalMenu";
import Footer from "./footer"
import ClientAction from "../actions/ClientAction";
import ConsultantAction from "../actions/ConsultantAction";
import factureAction from "../actions/factureAction";
import AddclientModal from "./Modals/addClientModal";
import AddConsultantModal from "./Modals/addConsultantModal";
import 'react-notifications/lib/notifications.css';
import CustomNotification from "./CustomNotification";
import BillsTable from "./BillsTable";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import './CalanderStyle.css';
import { Spinner } from 'react-bootstrap';



class Calendar extends Component {

    getDaysInMonth = date =>
        new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    pushSelectedDays(d) {

        const days = [];
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (date.getDay() !== 0 && date.getDay() !== 6)
                days.push(date);
        }
        this.setState({
            selectedDays: days,
            maxDays: days,
            nbjourAfacture: days.length,
            date: d
        });
    }

    constructor(props) {
        super(props);
        const d = new Date();
        const days = [];
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (date.getDay() !== 0 && date.getDay() !== 6)
                days.push(date);
        }
        const nbJourAFacture = new URLSearchParams(window.location.search).get('nbJourAfacturer');

        this.state = {
            showModalClient:false,
            showModalConsultant:false,
            blocking: false,
            selectedDays: days,
            maxDays: days,
            selectedBill: null,
            disabledDay: [{daysOfWeek: [0, 6]}],
            selectedClient: 0,
            selectedConsultant: 0,
            nbjourAfacture: nbJourAFacture !== null ? parseInt(nbJourAFacture) : days.length,
            TJM: 0,
            TVA: 20,
            activite: "",
            date: new Date(),
            client: {},
            clientId: {},
            consultantId: {},
            cra: {},
            consultant: {},
            somme: {},
            clients: [
                {}
            ],
            variableList: [
                {}
            ],
            clientsSelect: [
                {}
            ],
            bills: [],
            selectedClientValid: false,
            selectedConsultantValid: false,
            TJMValid: false,
            activiteValid: false,
            TVAValid: false,
            refrech: true,
            loading: false

        };
        this.getAllBills = this.getAllBills.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.getAllClient = this.getAllClient.bind(this);
        this.getAllConsultant = this.getAllConsultant.bind(this);
        this.clientHandleChange = this.clientHandleChange.bind(this);
        this.consultantHandleChange = this.consultantHandleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.TJMHandler = this.TJMHandler.bind(this);
        this.TVAHandler = this.TVAHandler.bind(this);
        this.activiteHandler = this.activiteHandler.bind(this);
        this.sommeHandler = this.sommeHandler.bind(this);
        this.generateFacture = this.generateFacture.bind(this);
        this.updateBillDetails = this.updateBillDetails.bind(this);
        this.appendDays = this.appendDays.bind(this);
        this.splitDays = this.splitDays.bind(this);
        this.handleBlock = this.handleBlock.bind(this);
        this.pushSelectedDays = this.pushSelectedDays.bind(this);
        this.setSelectedConsultant = this.setSelectedConsultant.bind(this);
        this.setSelectedClient = this.setSelectedClient.bind(this);
        this.resetFacture = this.resetFacture.bind(this);
        this.deleteBill = this.deleteBill.bind(this);
    }

    getAllBills() {
        this.setState({ loading: true }); 
        let billsResponse = [];
        factureAction.gelAllBills().then(
            response => {
                response.data.map(bill => billsResponse.push({
                    id: bill.id,
                    fnId: bill.fnId,
                    clientName: bill.clientName,
                    consultantName: bill.consultantName,
                    amountToPay: bill.amountToPay,
                    client: bill.client,
                    consultant: bill.consultant,
                    tjm: bill.tjm,
                    activity: bill.activity,
                    days: bill.days,
                    date:bill.date
                }));
                this.setState({bills: billsResponse})
                this.setState({ loading: false });
            }
            
        )
    }

    componentWillMount() {
        const days = new URLSearchParams(window.location.search).get('days');
        const date = new URLSearchParams(window.location.search).get('date');
        console.log(date)
        console.log(new Date(date))
        if (days !== null && days !== "" && date !== null && date !== "")
            this.splitDays(days,new Date(date));
    }

    updateBillDetails(bill) {
        this.setState({
            selectedbill: bill.id,
            selectedClient: bill.client + "",
            selectedConsultant: bill.consultant + "",
            TJM: bill.tjm,
            activite: bill.activity,
            somme: bill.amoutToPay,
            fnId: bill.fnId,
            nbjourAfacture: bill.days.split(',').length,
            date:bill.date
        });
        this.splitDays(bill.days,bill.date);
    }

    TJMHandler(e) {
        this.setState({
            TJM: e.target.value,
            TJMValid: false,

        });
    }

    activiteHandler(e) {
        this.setState({
            activite: e.target.value,
            activiteValid: false,
        });
    }

    nbrJourHandle(e) {
        this.setState({
            nbjourAfacture: e.target.value,
        });
    }


    TVAHandler(e) {
        this.setState({
            TVA: e.target.value,
            TVAValid: false
        });
    }

    sommeHandler(e) {
        this.setState({
            somme: e.target.value
        });
    }

    handleDayClick(day, {selected}) {
        const {selectedDays} = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({selectedDays});
        this.setState({nbjourAfacture: selectedDays.length});
    }


    getAllClient() {
        this.setState({blocking: false});
        var clients = [];
        ClientAction.getAllClients(1).then(
            response => {
                response.data.map((client) => clients.push({text: client.nomEntreprise, id: client.id}));
                this.setState({clients: clients})
                this.setState({blocking: false});

                const idClient = new URLSearchParams(window.location.search).get('client');
                this.setState({selectedClient: idClient});
            }
        )
    }

    getAllConsultant() {
        this.setState({blocking: true});
        var consultants = [];
        ConsultantAction.getAllConsultants().then(
            response => {
                response.data.map((consultant) => consultants.push({
                    text: consultant.name,
                    id: consultant.id
                }));
                this.setState({variableList: consultants})
                this.setState({blocking: false});
                const idConsultant = new URLSearchParams(window.location.search).get('id');
                this.setState({selectedConsultant: idConsultant});

            }
        )
    }


    componentDidMount() {
        this.getAllClient();
        this.getAllConsultant();
        this.getAllBills();
    }


    clientHandleChange(e) {
        this.setState({
            selectedClient: e.target.value,
            selectedClientValid: false

        });
    }

    consultantHandleChange(e) {
        this.setState({
            selectedConsultant: e.target.value,
            selectedConsultantValid: false
        });
    }

    generateFacture() {
        factureAction.generateFacture()
            .then();
    }


    appendDays() {
        let days = "";
        this.state.selectedDays.forEach(day => {
            days += day.getDate() + ","
        })
        days = days.slice(0, days.length - 1);
        return days;
    }

    splitDays(days,date) {
        let daysDate = days.split(",").map(day => parseInt(day));
        let selectedDays = [];
        let d= new Date(date)
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (daysDate.includes(date.getDate())) {
                selectedDays.push(date);
            }
        }
        this.setState({
            selectedDays: selectedDays,
            date: d
        })

    }

    handleSave() {

        if (this.state.selectedClient === 0 || this.state.selectedConsultant === 0 || this.state.TJM === ""
            || this.state.activite === "" || this.state.TVA === "" || parseInt(this.state.TJM) < 0 || parseInt(this.state.TVA) < 0) {

            if (this.state.selectedClient === "") this.setState({selectedClientValid: true});
            if (this.state.selectedConsultant === "") this.setState({selectedConsultantValid: true});
            if (this.state.TJM === "" || parseInt(this.state.TJM) < 0) this.setState({TJMValid: true});
            if (this.state.activite === "") this.setState({activiteValid: true});
            if (this.state.TVA === "" || parseInt(this.state.TVA) < 0) this.setState({TVAValid: true});

        } else {
            this.setState({blocking: true});
            const {date, TVA, TJM, nbjourAfacture, selectedClient, selectedConsultant, activite, selectedbill} = this.state;

            const cra = {
                nbJourAfacturer: nbjourAfacture,
                date: date
            };
            let total = Number.parseInt(nbjourAfacture) * Number.parseInt(TJM) + (Number.parseInt(nbjourAfacture) * Number.parseInt(TJM) * TVA / 100);
            const Bill = {
                id: selectedbill != null ? selectedbill : null,
                cra: cra,
                idclient: Number.parseInt(selectedClient),
                idconsultant: Number.parseInt(selectedConsultant),
                tgm: Number.parseInt(TJM),
                sommeapayer: total,
                tva: (Number.parseInt(nbjourAfacture) * Number.parseInt(TJM) * TVA / 100),
                sommeHt: Number.parseInt(nbjourAfacture) * Number.parseInt(TJM),
                activity: activite,
                days: this.appendDays()
            };

            factureAction.saveBill(Bill).then(
                () => {
                    this.setState({blocking: false});
                    CustomNotification.createNotification('success', 'Facture enregistrée', 'Facturation');
                    this.getAllBills();
                    this.setState({
                        selectedClient: 0,
                        selectedConsultant: 0,
                        TJM: 0,
                        TVA: 20,
                        activite: "",
                    })
                })
                .catch(() => {
                    this.setState({blocking: false});
                    CustomNotification.createNotification('error', 'Facture n\'est pas enregistrée', 'Facturation');
                });

        }
    }

    handleBlock(value) {
        this.setState({
            blocking: value
        })
    }

    setSelectedClient(id) {
        this.setState({
            selectedClient: id
        })
    }

    setSelectedConsultant(id) {
        this.setState({
            selectedConsultant: id
        })
    }

    resetFacture() {
        const d = new Date();
        const days = [];
        const nbrDaysinMonth = this.getDaysInMonth(d);
        for (let day = 1; day < nbrDaysinMonth; day++) {
            let date = new Date(d.getFullYear(), d.getMonth(), day);
            if (date.getDay() !== 0 && date.getDay() !== 6)
                days.push(date);
        }
        const nbJourAFacture = new URLSearchParams(window.location.search).get('nbJourAfacturer');
        this.setState({
            selectedDays: days,
            maxDays: days,
            selectedbill: null,
            disabledDay: [{daysOfWeek: [0, 6]}],
            selectedClient: 0,
            selectedConsultant: 0,
            nbjourAfacture: nbJourAFacture !== null ? parseInt(nbJourAFacture) : days.length,
            TJM: 0,
            TVA: 20,
            activite: "",
            date: new Date(),
            somme: {},

        })
    }

    deleteBill(id) {
        this.setState({
            bills: this.state.bills.filter(bill => bill.id !== id)
        })
    }

    render() {
        let nb = this.state.nbjourAfacture;
        let TJM = this.state.TJM;
        let TVA = this.state.TVA;
        let somme = nb * TJM + (nb * TJM * TVA / 100);
        const { loading } = this.state;

        return (

            <div>
                <BlockUi blocking={this.state.blocking}>
                    <AddclientModal handleBlock={this.handleBlock} getAllClient={this.getAllClient}
                                    setSelectedClient={this.setSelectedClient} showModal={this.state.showModalClient} closeModal={()=>this.setState({showModalClient:false,clientname:""})}/>
                    <AddConsultantModal handleBlock={this.handleBlock} getAllConsultant={this.getAllConsultant}
                                        setSelectedConsultant={this.setSelectedConsultant} showModal={this.state.showModalConsultant} closeModal={()=>this.setState({showModalConsultant:false})}/>
                    <HorizontalMenu/>
                    <div className="container-fluid page-body-wrapper" id='layout'>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row grid-margin">
                                    <div className="col-12">
                                        <div className="card" id='card-calendar'>
                                            <div className="card-body">
                                                { loading ? (
                                                    <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                  </div>
                                                ):(
                                                    <div className="form-group row">
                                                    <div className="table-responsive">
                                                        <BillsTable bills={this.state.bills}
                                                                    updateBillDetails={this.updateBillDetails}
                                                                    deleteBill={this.deleteBill}
                                                        />
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                                <th>Ajouter/Modifier une facture</th>
                                                                <th>
                                                                    <button type="submit"
                                                                            className="btn new-facture col-md-4"
                                                                            onClick={this.resetFacture}
                                                                    >Nouvelle facture
                                                                    </button>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <th>
                                                                    <div className="form-group row">
                                                                        <div className="col-lg-3">
                                                                            <label
                                                                                className="col-form-label">Jours facturés</label>
                                                                        </div>
                                                                        <div className="col-lg-8">
                                                                            <DayPicker
                                                                                localeUtils={MomentLocaleUtils}
                                                                                locale={"fr"}
                                                                                selectedDays={this.state.selectedDays}
                                                                                onDayClick={this.handleDayClick}
                                                                                disabledDays={this.state.disabledDay}
                                                                                onMonthChange={this.pushSelectedDays}
                                                                                month={new Date(this.state.date)}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`form-group ${this.state.activiteValid ? "has-danger" : ""} row`}>
                                                                        <div className="col-lg-5">
                                                                            <label
                                                                                className="col-form-label">Nombre de
                                                                                jour à facturer </label>
                                                                        </div>
                                                                        <div className="col-lg-7">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text bg-primary text-white">N</span>

                                                                                        <input type="number"
                                                                                               max={this.state.maxDays.length}
                                                                                               min={0}
                                                                                               value={this.state.nbjourAfacture}
                                                                                               className="form-control"
                                                                                               placeholder="Nombre"
                                                                                               aria-label="Amount (to the nearest dollar)"
                                                                                               onChange={(e) => this.nbrJourHandle(e)}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th>
                                                                    <div
                                                                        className={`form-group ${this.state.selectedClientValid ? "has-danger" : ""} row`}>
                                                                        <div className="col-lg-3">
                                                                            <label className="col-form-label">Client
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-7">
                                                                            <Select2
                                                                                ref="listeClients"
                                                                                value={this.state.selectedClient}
                                                                                data={this.state.clients}
                                                                                onChange={this.clientHandleChange}
                                                                                options={{
                                                                                    placeholder: 'recherche par nom'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-1">
                                                                            <i
                                                                               style={{
                                                                                   cursor: 'pointer',
                                                                                   margin: '0.7em 0 0 1.5em',
                                                                                   position: 'absolute'
                                                                               }}
                                                                               onClick={()=>this.setState({showModalClient:true})}
                                                                               className="mdi mdi-account-plus"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`form-group ${this.state.selectedConsultantValid ? "has-danger" : ""} row`}>
                                                                        <div className="col-lg-3">
                                                                            <label
                                                                                className="col-form-label">Consultant</label>
                                                                        </div>
                                                                        <div className="col-lg-7">
                                                                            <Select2
                                                                                ref="listeConsultants"
                                                                                value={this.state.selectedConsultant}
                                                                                data={this.state.variableList}
                                                                                onChange={this.consultantHandleChange}
                                                                                options={{
                                                                                    placeholder: 'recherche par nom'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-1">
                                                                            <i
                                                                               style={{
                                                                                   cursor: 'pointer',
                                                                                   margin: '0.7em 0 0 1.5em',
                                                                                   position: 'absolute'
                                                                               }}
                                                                               onClick={()=>this.setState({showModalConsultant:true})}
                                                                               className="mdi mdi-account-plus"></i>
                                                                        </div>

                                                                    </div>
                                                                    <div
                                                                        className={`form-group ${this.state.activiteValid ? "has-danger" : ""} row`}>
                                                                        <div className="col-lg-3">
                                                                            <label
                                                                                className="col-form-label">Activité </label>
                                                                        </div>
                                                                        <div className="col-lg-8">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text bg-primary text-white">A</span>

                                                                                        <input type="text"
                                                                                               value={this.state.activite}
                                                                                               className="form-control"
                                                                                               placeholder="Activité"
                                                                                               aria-label="Amount (to the nearest dollar)"
                                                                                               onChange={(e) => this.activiteHandler(e)}/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`form-group ${this.state.TJMValid ? "has-danger" : ""} row`}>
                                                                        <div className="col-lg-3">
                                                                            <label
                                                                                className="col-form-label">TJM </label>
                                                                        </div>
                                                                        <div className="col-lg-8">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text bg-primary text-white">
                                                                                            <i className="mdi mdi-currency-eur"/></span>

                                                                                        <input type="number"
                                                                                               value={this.state.TJM}
                                                                                               className="form-control"
                                                                                               aria-label="Amount (to the nearest dollar)"
                                                                                               onChange={(e) => this.TJMHandler(e)}/>

                                                                                        <div
                                                                                            className="input-group-append">
                                                                                            <span
                                                                                                className="input-group-text">.00</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`form-group ${this.state.TVAValid ? "has-danger" : ""} row`}>
                                                                        <div className="col-lg-3">
                                                                            <label
                                                                                className="col-form-label">TVA </label>
                                                                        </div>
                                                                        <div className="col-lg-8">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text bg-primary text-white">%</span>

                                                                                        <input type="number"
                                                                                               value={this.state.TVA}
                                                                                               className="form-control"
                                                                                               aria-label="Amount (to the nearest dollar)"
                                                                                               onChange={(e) => this.TVAHandler(e)}/>
                                                                                        <div
                                                                                            className="input-group-append">
                                                                                            <span
                                                                                                className="input-group-text">.00</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group row">
                                                                        <div className="col-lg-3">
                                                                            <label className="col-form-label">Somme
                                                                                facturée
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-8">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                        <span
                                                                                            className="input-group-text bg-primary text-white"><i
                                                                                            className="mdi mdi-currency-eur"></i></span>

                                                                                        <input type="number"
                                                                                               className="form-control"
                                                                                               aria-label="Amount (to the nearest dollar)"
                                                                                               value={somme}
                                                                                               disabled="disabled"/>
                                                                                        <div
                                                                                            className="input-group-append">
                                                                                            <span
                                                                                                className="input-group-text">.00</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td align="center">
                                                                    <button type="submit"
                                                                            className="btn btn-success col-md-4"
                                                                            onClick={this.handleSave}
                                                                    >Enregistrer
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                )}
                                              
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer className="footer-relative"/>
                </BlockUi>
            </div>
        );
    }
}

export default Calendar;