import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";


export function ConfirmationModal(props) {
    const {showConfirmModal, setShowConfirmModal, action} = props;
    const handleClose = () => {
        setShowConfirmModal(false);
    };

    const doAction = () => {
        action();
        handleClose();
    }
    return (
        <>
            <Modal centered show={showConfirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>La suppression sera définitive, êtes-vous sûr de vouloir la valider ?</Modal.Body>
                <Modal.Footer>
                    <button type="submit"
                            className="btn btn-default col-md-4"
                            onClick={handleClose}
                    >Annuler
                    </button>
                    <button type="submit"
                            className="btn btn-primary col-md-4"
                            onClick={doAction}
                    >Enregistrer
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
