import React, {Component} from 'react';
import ClientAction from "../actions/ClientAction";
import CustomNotification from "./CustomNotification";
import ClientTable from "./ClientTable";
import InputTextGroup from "./InputTextGroup";
import 'react-block-ui/style.css';

class FormAddClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            nomClient: "",
            nomEntreprise: "",
            adresse: "",
            tel: "",
            mail: "",
            nomClientValid: "",
            siteweb: "",
            codePostal: "",
            remarque: "",
            finale: false,
            favorite: false,
            client: {},
            date: "",
            clientId: {},
            selectedClientValid: false,
            refrech: true,
            error: {
                nomClient: false,
                nomEntreprise: false,
                adresse: false,
                tel: false,
                mail: false,
                nomClientValid: false,
                siteweb: false,
                codePostal: false,
                remarque: false,
                finale: false,
                favorite: false,
                client: false,
                date: false,
            }

        };
        this.updateClientDetails = this.updateClientDetails.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.checkboxChange = this.checkboxChange.bind(this);
        this.deleteClient = this.deleteClient.bind(this);
    }

    checkboxChange(event) {
        let {name: fieldName, checked} = event.target;
        this.setState({
            [fieldName]: checked
        });
    }

    handleInputChange(event) {
        let {name: fieldName, value} = event.target;
        this.setState({
            [fieldName]: value
        });
        let errorTmp = {};
        if (value !== "") {
            errorTmp = {...this.state.error, [fieldName]: false}
            this.setState({
                error: errorTmp
            });
        }
    };

    updateClientDetails(client) {
        this.setState({
            selectedClient: client.id,
            nomClient: client.nomClient||"",
            nomEntreprise: client.nomEntreprise||"",
            adresse: client.adresse||"",
            tel: client.tel||"",
            mail: client.mail||"",
            nomClientValid: client.nomClientValid||"",
            siteweb: client.siteweb||"",
            codePostal: client.codePostal||"",
            remarque: client.remarque||"",
            finale: client.finale||"",
            favorite: client.favorite||""
        });
    }


    handleSave(event) {

        const {
            nomClient, nomEntreprise, adresse, tel, mail, nomClientValid, siteweb, codePostal, remarque, selectedClient, finale, favorite, error
        } = this.state;

        if (nomEntreprise === "") {
            let errorTmp = {};
            for (let v in error) {
                if (this.state[v] === "") {
                    errorTmp = {...errorTmp, [v]: true}
                }
            }
            this.setState({
                error: errorTmp
            });
        } else {
            this.setState({blocking: true});
            event.preventDefault();
            let client = {
                id: selectedClient != null ? selectedClient : null,
                nomClient: nomClient,
                nomEntreprise: nomEntreprise,
                adresse: adresse,
                tel: tel,
                mail: mail,
                nomClientValid: nomClientValid,
                siteweb: siteweb,
                codePostal: codePostal,
                remarque: remarque,
                finale: finale,
                favorite: favorite
            };
            if(selectedClient==null){
                ClientAction.saveClient(client).then(
                    () => {
                        CustomNotification.createNotification('success', 'Client enregistré', 'Gestion des clients');
                        this.setState({blocking: false});
                        this.props.getAllClient()

                    })
                    .catch(() => {
                        this.setState({blocking: false});
                        CustomNotification.createNotification('error', 'Le client n\'a pas été enregistré', 'Gestion des clients');
                    });
            }
            if(selectedClient != null){
                ClientAction.patchClient( client.id , client).then(
                    () => {
                        CustomNotification.createNotification('success', 'Client enregistré', 'Gestion des clients');
                        this.setState({blocking: false});
                        this.props.getAllClient()
                    })
                    .catch(() => {
                        this.setState({blocking: false});
                    //    CustomNotification.createNotification('error', 'Le client n\'a pas été enregistré', 'Gestion des clients');
                    });
            }
           
        }

    }

    resetForm(){
        this.setState({
            nomClient: "",
            nomEntreprise: "",
            adresse: "",
            tel: "",
            mail: "",
            nomClientValid: "",
            siteweb: "",
            codePostal: "",
            remarque: "",
            finale: false,
            favorite: false,
            client: {},
            date: "",
            clientId: {},
            selectedClientValid: false,
            error: {
                nomClient: false,
                nomEntreprise: false,
                adresse: false,
                tel: false,
                mail: false,
                nomClientValid: false,
                siteweb: false,
                codePostal: false,
                remarque: false,
                finale: false,
                favorite: false,
                client: false,
                date: false,
            },
            selectedClient :null

        })
    }

    deleteClient(id) {
        this.props.deleteClient(id)
    }

    render() {
        const {
            nomClient, nomEntreprise, adresse, tel, mail, nomClientValid, siteweb, codePostal, remarque, favorite, finale, error,blocking
        } = this.state;
        return (
            <div className="form-group">
                    <ClientTable clients={this.props.clients}
                                 updateClientDetails={this.updateClientDetails}
                                 deleteClient={this.deleteClient}/>

                    <table className="table">
                        <thead>
                            <tr>
                                <th colSpan="2">Ajouter/modifier un client</th>
                                <th>
                                    <button type="submit"
                                            className="btn new-facture "
                                            onClick={this.resetForm}
                                    >Nouveau client
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>
                                    Entreprise
                                </td>
                                <td className="td-imput">
                                    <InputTextGroup name="nomEntreprise"
                                                    placeholder="Entreprise"
                                                    value={nomEntreprise}
                                                    type="text"
                                                    handleInputChange={this.handleInputChange}
                                                    error={error.nomEntreprise?"true":"false"}
                                                    disabled={blocking}
                                    />
                                </td>
                                <td>
                                    Nom du contact
                                </td>
                                <td className="td-imput">
                                    <InputTextGroup name="nomClient"
                                                    placeholder="Nom"
                                                    value={nomClient}
                                                    type="text"
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Mail
                                </td>
                                <td>
                                    <InputTextGroup name="mail"
                                                    placeholder="Mail"
                                                    value={mail}
                                                    type="email"
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                                <td>
                                    Tél
                                </td>
                                <td>
                                    <InputTextGroup name="tel"
                                                    placeholder="Tel"
                                                    value={tel}
                                                    type="number"
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Adresse
                                </td>
                                <td>
                                    <InputTextGroup name="adresse"
                                                    placeholder="Adresse"
                                                    value={adresse}
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                                <td>
                                    Code postal
                                </td>
                                <td>
                                    <InputTextGroup name="codePostal"
                                                    placeholder="Code postal"
                                                    value={codePostal}
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    Site Web
                                </td>
                                <td>
                                    <InputTextGroup name="siteweb"
                                                    placeholder="Site Web"
                                                    value={siteweb}
                                                    type="text"
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                                <td>
                                    Remarque
                                </td>
                                <td>
                                    <InputTextGroup name="remarque"
                                                    placeholder="Remarque"
                                                    value={remarque}
                                                    type="text"
                                                    handleInputChange={this.handleInputChange}
                                                    required={false}
                                                    disabled={blocking}
                                    />
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    Client Final
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked={finale} name="finale"
                                            onChange={this.checkboxChange}/>
                                        <span className="slider"></span>
                                    </label>
                                </td>
                                <td>
                                    Favori
                                </td>
                                <td>
                                    <label className="switch">
                                        <input type="checkbox" checked={favorite} name="favorite"
                                            onChange={this.checkboxChange}/>
                                        <span className="slider"></span>
                                    </label>
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                                <td></td>
                                <td align="right">
                                    <button className="btn btn-info col-md-12"
                                            onClick={this.resetForm}
                                    >Réinitialiser
                                    </button>
                                </td>
                                <td align="right">
                                    <button className="btn btn-success col-md-8"
                                            onClick={this.handleSave}
                                    >Enregistrer {blocking && <i className="fa fa-spinner fa-spin"/>}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>

        );
    }
}

export default FormAddClient;