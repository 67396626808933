import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import InputTextGroup from "../InputTextGroup";


export function AddYearModal(props) {
    const {showConfirmModal, setShowConfirmModal, action} = props;

    const [year,setYear]=useState(0);
    const handleClose = () => {
        setShowConfirmModal(false);
    };

    const handleInputChange = (event) => {
        setYear(event.target.value)
    };
    const doAction = () => {
        action(year);
        handleClose();
    };
    return (
        <>
            <Modal centered show={showConfirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une année pour un consultant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputTextGroup name="year"
                                    value={year}
                                    type="number"
                                    handleInputChange={handleInputChange}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success"
                            onClick={doAction}
                    >Enregistrer
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
