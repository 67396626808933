import React, { useState, useEffect } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import './SimpleUserNavBarStyles.css';
import userAction from '../actions/UserAction';


function SimpleUserNavBar() {
  const [clicked, setClicked] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    if (userAction.getToken()) {
      // do nothing
    } else {
      setRedirectToReferrer(true);
    }
  }, []);

  function logout() {
    sessionStorage.setItem('data', '');
    sessionStorage.clear();
    setRedirectToReferrer(true);
  }

  if (redirectToReferrer) {
    return <Navigate to={'/login'} />;
  }

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <>
      <nav>
        <div className="brand-logo">
          <NavLink to={sessionStorage.getItem('role') === 'ROLE_ADMIN' ? '/calendar' : '/calendar-user'}>
          </NavLink>
        </div>

        <div>
          <ul id="navBarUser" className={clicked ? "#navBarUser active" : "navBarUser"}>
           
           
          
           
            

            <li >
            <NavLink to="/profile">
                  <label className="email" >{sessionStorage.getItem('email')}</label>
                </NavLink>
            </li>
            <li>
              <a onClick={logout}>
                <i className="mdi mdi-logout" />
                Déconnexion
              </a>
            </li>
          </ul>
        </div>
    
      
     
      </nav>
    </>
  );
};

export default SimpleUserNavBar;
