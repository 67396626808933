import React, {useState} from 'react';
import MaterialTable from 'material-table';
import factureAction from "../actions/factureAction";
import {ConfirmationModal} from "./Modals/ConfirmationModal";
import CustomNotification from "./CustomNotification";
import CircularProgress from "@material-ui/core/CircularProgress";

type
Props = {
    bills: Object[],
    updateBillDetails: Function
}

export default function BillsTable({bills, updateBillDetails,deleteBill}: Props) {
    const [id, setId] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const columns = [
        {title: 'N° Facture', field: 'fnId'},
        {title: 'Client', field: 'clientName'},
        {title: 'Consultant', field: 'consultantName'},
        {
            title: 'Somme facturée',
            field: 'amountToPay',
            render: rowData => (
                <div>
                    {rowData.amountToPay} €
                </div>

            )
        },
    ];

    const getBillPdf = (fnId) => {
        factureAction.generateFacture(fnId)
            .then(
                response => {
                    window.open(response.config.url);
                });
    };

    const getBillToUpdate = (id) => {
        let c = bills.filter(c => c.id === id);
        updateBillDetails(c[0]);
    }

    const deleteFacture = () => {
        factureAction.deleteBill(id).then(
            () => {
                CustomNotification.createNotification('success', 'Facture supprimée', 'Gestion des factures');
                deleteBill(id)
            })
            .catch(() => {
                CustomNotification.createNotification('error', 'La facture n\'a pas été supprimée', 'Gestion des factures');
            });
    }
    const deleteFactureModal = (id) => {
        if (id !== undefined) {
            setId(id)
            setShowConfirmModal(true);
        }
    };

    return (
        <>
            <MaterialTable
                title="Factures"
                columns={columns}
                data={bills}
                options={{
                    loadingType:'overlay'
                }}
                actions={[
                    {
                        icon: 'save',
                        tooltip: 'Télécharger la facture en PDF',
                        onClick: (event, rowData) => getBillPdf(rowData.fnId,)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier',
                        onClick: (formation, rowData) => getBillToUpdate(rowData.id)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Supprimer',
                        onClick: (formation, rowData) => deleteFactureModal(rowData.id)
                    }
                ]}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'Factures'
                    },
                    toolbar: {
                        nRowsSelected: '{0} facture(s) sélectionnée(s)',
                        searchPlaceholder: 'Recherche',
                        searchTooltip: 'Recherche'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: bills!== undefined && bills.length!==0?<CircularProgress />:"Vous n'avez pas créé de facture",
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
            />
            <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal}
                               action={() => deleteFacture()}/>
        </>
    );
}