import React, {useState,useEffect} from 'react';


export default function InputTextGroup({... props}) {


    const [state, setState] = useState({
        value: props.value,
        error: props.error,
    });

    const handleInputChange = (event) => {
        var value = event.target.value;
        if(props.type === "number" && value<0){
            event.target.value = 0;
            setState({...state,value: 0});
        }else{
            setState({...state,value: value});
        }
        props.handleInputChange(event)
        verifInput(event)
    };

    useEffect(()=>{
        setState({...state, value: props.value,error:props.error});
    },[props.value,props.error]);

    const verifInput = (event) => {
        if (!props.optional) {

            if (event.target.value === "") {
                setState({...state, error: "true"});
            } else {
                setState({...state, error: "false"});
            }
        }
    }
    return (
        <>
            {
                props.required !== false ?
                    <div
                        className={`form-group ${state.error === "true" ? "has-danger" : ""} row`}>
                        {props.i ?
                            <div className="input-group-prepend">
                        <span className="input-group-text bg-primary text-white">
                            {props.i}
                        </span>


                                <input required name={props.name}
                                       value={state.value}
                                       className="form-control"
                                       placeholder={props.placeholder}
                                       onChange={handleInputChange}
                                       onBlur={verifInput}
                                       type={props.type}
                                       readOnly={props.disabled}
                                />
                            </div> :
                            <div className="input-group-prepend">
                                <input required name={props.name}
                                       value={state.value}
                                       className="form-control"
                                       placeholder={props.placeholder}
                                       onChange={handleInputChange}
                                       onBlur={verifInput}
                                       type={props.type}
                                       readOnly={props.disabled}

                                />
                            </div>
                        }
                        {
                            state.error === "true" ? <label className="error-msg">Ce Champs est obligatoire </label> : ""
                        }

                    </div>
                    :
                    <div
                        className={`form-group row`}>
                        <input name={props.name}
                               value={state.value}
                               className="form-control"
                               placeholder={props.placeholder}
                               onChange={handleInputChange}
                               type={props.type}
                               readOnly={props.disabled}

                        />
                    </div>

            }
        </>
    );

};
