import React, { Component } from 'react';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import HorizontalMenu from "./horizontalMenu";
import Footer from "./footer"
import FormationAction from "../actions/FormationAction";
import 'react-notifications/lib/notifications.css';
import FormAddFormation from "./FormAddFormation";
import './FormationStyles.css';
import { Spinner } from 'react-bootstrap';


class Formation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            blocking: false,
            formationToUpdate: {},
            formations: [],
            refrech: true,
            loading: false
        };

        this.getAllFormation = this.getAllFormation.bind(this);
        this.deleteFormation = this.deleteFormation.bind(this);
    }



    getAllFormation() {
        this.setState({ loading: true });
        var formations = [];
        FormationAction.getAllFormations().then(
            response => {
                response.data.map((formation) => formations.push(formation));
                if (response.data.length === 0) {
                    this.setState({ formations: [] })
                } else {
                    this.setState({ formations: formations })
                }
                this.setState({ loading: false });
            }
        )
    }

    componentDidMount() {
        this.getAllFormation();
    }

    deleteFormation(id) {
        this.setState({
            formations: this.state.formations.filter(formation => formation.id !== id)
        })
    }

    render() {
        const { formations, formationToUpdate, loading } = this.state;

        return (

            <div>
                <HorizontalMenu />
                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card" id='card-formation'>
                                        <div className="card-body">
                                            {loading ? (
                                                <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                                <FormAddFormation
                                                    formations={formations}
                                                    deleteFormation={this.deleteFormation}
                                                    getAllFormation={() => this.getAllFormation()}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer className="footer-relative" />
            </div>
        );
    }
}

export default Formation;