import React, {useState} from 'react';
import MaterialTable from 'material-table';
import EventAction from "../actions/EventAction";
import CustomNotification from "./CustomNotification";
import {ConfirmationModal} from "./Modals/ConfirmationModal";
import CircularProgress from "@material-ui/core/CircularProgress";


type
Props = {
    events: Object[],
    updateEventDetails: Function
};

export default function EventTable({events, updateEventDetails,deleteEvent}: Props) {

    const [id, setId] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const columns = [
        {title: 'Nom', field: 'eventName'},
        {title: 'Adresse', field: 'adresse'},
        {title: 'Date', field: 'date', type: "date", dateSetting: { locale: "fr-FR" }},
        {title: 'Durée (jours)', field: 'duration'},
        {
            title: 'Participation',
            field: 'participation',
            lookup: {true: 'Oui', false: 'Non'},
        },
    ];

    const deleteEventService = () => {

        EventAction.deleteEvent(id).then(
            () => {
                CustomNotification.createNotification('success', 'Evénement supprimé', 'Gestion des événements');
                deleteEvent(id)
            })
            .catch((err) => {
                console.log(err)
                CustomNotification.createNotification('error', 'L\'événement n\'a pas été supprimé', 'Gestion des événements');
            });
    }

    const getEventToUpdate = (id) => {
        let c = events.filter(c => c.id === id);
        updateEventDetails(c[0]);
    };

    const deleteClientInModal = (id) => {
        if (id !== undefined) {
            setId(id)
            setShowConfirmModal(true);
        }
    }

    return (
        <>
            <MaterialTable
                style={{width: "100%"}}
                title="Evénements"
                columns={columns}
                data={events}
                options={{
                    filtering: true,
                    loadingType:'overlay'
                }}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Supprimer',
                        onClick: (event, rowData) => deleteClientInModal(rowData.id)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier',
                        onClick: (event, rowData) => getEventToUpdate(rowData.id)
                    }
                ]}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'Evénements'
                    },
                    toolbar: {
                        nRowsSelected: '{0} événement(s) sélectionné(s)',
                        searchPlaceholder: 'Recherche',
                        searchTooltip: 'Recherche'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: events.length!==0?<CircularProgress />:" Vous n'avez pas ajouté d'événement.",
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
            /> <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal}
                                  action={() => deleteEventService()}/>

        </>
    );
}