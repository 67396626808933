import React, {Component} from 'react';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';
import Footer from "./footer"
import UserAction from "../actions/UserAction";
import 'react-notifications/lib/notifications.css';
import TopNavbar from "./TopNavbar";
import InputTextGroup from "./InputTextGroup";
import CustomNotification from "./CustomNotification";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import NavBar from './NavBar';
import './ProfileStyles.css';
import SimpleUserNavBar from "./SimpleUserNavBar";
import { Spinner } from 'react-bootstrap';


class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadinf :false,
            blocking: false,
            email: "",
            password: "",
            entrepriseName: "",
            adresse: "",
            codePostal: "",
            tel: "",
            banque: null,
            rib: null,
            iban_bic: null,
            siret: null,
            ntva: null,
            profile: {},
            error: {
                email: false,
                password: false,
                entrepriseName: false,
                adresse: false,
                codePostal: false,
                tel: false,
                banque: false,
                rib: false,
                iban_bic: false,
                siret: false,
                ntva: false,
            }
        };

        this.getProfile = this.getProfile.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        let {name: fieldName, value} = event.target;
        this.setState({
            [fieldName]: value
        });
        let errorTmp = {};
        if (value !== "") {
            errorTmp = {...this.state.error, [fieldName]: false}
            this.setState({
                error: errorTmp
            });
        }
    };

    getProfile() {
        this.setState({ loading: true });

        UserAction.getByEamil(sessionStorage.getItem('email')).then(
            response => {
                this.setState({
                    profile: response.data,
                    id: response.data.id,
                    email: response.data.email,
                    password: "*****",
                    entrepriseName: response.data.entrepriseName,
                    adresse: response.data.adresse,
                    codePostal: response.data.codePostal,
                    tel: response.data.tel,
                    banque: response.data.banque,
                    rib: response.data.rib,
                    iban_bic: response.data.iban_bic,
                    siret: response.data.siret,
                    ntva: response.data.ntva,
                })
                this.setState({ loading: false });

            }
        )
    }

    handleSave(events) {

        const {
            profile, error, email, password, entrepriseName, adresse, codePostal, tel, banque, rib, iban_bic, siret, ntva
        } = this.state;

        if (email === "" || adresse === "" || password === "" || entrepriseName === ""
            || codePostal === "" || tel === "") {
            let errorTmp = {};
            for (let v in error) {
                if (this.state[v] === "") {
                    errorTmp = {...errorTmp, [v]: true}
                }
            }
            this.setState({
                error: errorTmp
            });
        } else {
            this.setState({blocking: true});
            events.preventDefault();
            let passUpdated = password !== "*****" ;
            let user = {
                id: profile.id,
                email: email,
                password: !passUpdated ? profile.password : password,
                entrepriseName: entrepriseName,
                adresse: adresse,
                codePostal: codePostal,
                tel: tel,
                banque: banque,
                rib: rib,
                iban_bic: iban_bic,
                siret: siret,
                ntva: ntva,
            };
            UserAction.updateUser(user,passUpdated).then(
                () => {
                    this.setState({blocking: false});
                    CustomNotification.createNotification('success', 'Prolife Modfié', 'Gestion Profile');
                })
                .catch(() => {
                    this.setState({blocking: false});
                    CustomNotification.createNotification('error', 'Profil n\'est pas modifié', 'Gestion Profile');
                });
        }

    }

    componentDidMount() {
        this.getProfile();
    }



    render() {
        const {
            error, email, password, entrepriseName, adresse, codePostal, tel, banque, rib, iban_bic, siret, ntva,loading
        } = this.state;

        return (

            <div>
                <div className="horizontal-menu">
                    {
                        sessionStorage.getItem('role') === 'ROLE_ADMIN' ?  <NavBar/> : <SimpleUserNavBar/>
                        }
                    
                   
                </div>
                <BlockUi blocking={this.state.blocking}>
                    <div className="container-scroller" >
                        <div className="container-fluid page-body-wrapper full-page-wrapper" >
                            <div className="main-panel" >
                                <div className="content-wrapper d-flex align-items-center auth px-0">
                                    <div className="row w-100 mx-0" id='card-profile'>
                                        <div className="col-lg-4 mx-auto">
                                        {loading ? (
                                                <div className="text-center">
                                                    <Spinner animation="border" variant="primary" />
                                                </div>
                                            ) : (
                                               <>
                                               <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                                <h4>Modifier vos informations</h4>
                                                <br/>
                                                <br/>
                                                <InputTextGroup name="email"
                                                                placeholder="Nom"
                                                                value={email}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.email?"true":"false"}
                                                />
                                                <InputTextGroup name="password"
                                                                placeholder="Mot de passe"
                                                                value={password}
                                                                type="password"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.password?"true":"false"}
                                                />
                                                <InputTextGroup name="entrepriseName"
                                                                placeholder="Nom de votre entreprise"
                                                                value={entrepriseName}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.entrepriseName?"true":"false"}
                                                />
                                                <InputTextGroup name="adresse"
                                                                placeholder="Adresse"
                                                                value={adresse}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={adresse.email?"true":"false"}
                                                />
                                                <InputTextGroup name="codePostal"
                                                                placeholder="Code postal"
                                                                value={codePostal}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.codePostal?"true":"false"}
                                                />
                                                <InputTextGroup name="tel"
                                                                placeholder="Numéro de téléphone"
                                                                value={tel}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.tel?"true":"false"}
                                                />
                                                <InputTextGroup name="banque"
                                                                placeholder="Banque"
                                                                value={banque}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.banque?"true":"false"}
                                                                optional
                                                />
                                                <InputTextGroup name="Rib"
                                                                placeholder="RIB"
                                                                value={rib}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.rib?"true":"false"}
                                                                optional
                                                />
                                                <InputTextGroup name="iban_bic"
                                                                placeholder="IBAN - BIC"
                                                                value={iban_bic}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.iban_bic?"true":"false"}
                                                                optional
                                                />
                                                <InputTextGroup name="siret"
                                                                placeholder="SIRET"
                                                                value={siret}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.siret?"true":"false"}
                                                                optional
                                                />
                                                <InputTextGroup name="ntva"
                                                                placeholder="N° TVA"
                                                                value={ntva}
                                                                type="text"
                                                                handleInputChange={this.handleInputChange}
                                                                error={error.ntva?"true":"false"}
                                                                optional
                                                />
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    <button className="btn btn-success col-md-8"
                                                            onClick={this.handleSave}
                                                    >Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                               </>
                                            )}
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer className="footer-relative"/>
                </BlockUi>
            </div>
        );
    }
}

export default Profile;